<h2 class="cb-title">Colissimo</h2>

<mat-tab-group>

  <mat-tab label="Prix">

    @if (this.colissimoPrices.length) {
      <table mat-table [dataSource]="this.colissimoPrices">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">
            {{ element.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef>Poids</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="cb-form-field" appearance="outline">
              <input
                class="cb-form-input"
                matInput
                [min]="0"
                type="number"
                [value]="element.weight"
                required
              />
            </mat-form-field>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="priceHome">
          <th mat-header-cell *matHeaderCellDef>Prix Domicile</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="cb-form-field" appearance="outline">
              <input
                class="cb-form-input"
                matInput
                [min]="0"
                type="number"
                [value]="element.priceHome | toPrice"
                required
              />
              <mat-icon matSuffix>euro</mat-icon>
            </mat-form-field>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="priceRelay">
          <th mat-header-cell *matHeaderCellDef>Prix Relais</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="cb-form-field" appearance="outline">
              <input
                class="cb-form-input"
                matInput
                [min]="0"
                type="number"
                [value]="element.priceRelay | toPrice"
                required
              />
              <mat-icon matSuffix>euro</mat-icon>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button matTooltip="Modifier" [disabled]="true">
              <mat-icon>check</mat-icon>
            </button>
            <button mat-icon-button class="cb-delete-button" matTooltip="Supprimer">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      </table>
    }

    @else {
      @if (this.isLoading) {
        <div class="cb-admin-products-loading">
          <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
      }

      @else {
        <p>Aucun poids est configuré</p>
      }
    }

    <button
      mat-fab
      class="cb-admin-promo-codes-button"
      color="accent"
      matTooltip="Ajouter"
      (click)="this.openDialog()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </mat-tab>

</mat-tab-group>