<mat-dialog-content class="cb-admin-settings-containers-modal works">

	@if (this.isLoading) {
		<div class="cb-admin-settings-containers-modal-loading">
			<mat-progress-spinner
				diameter="48"
				mode="indeterminate"
			></mat-progress-spinner>
		</div>
	}

	<div class="cb-admin-settings-containers-heading">
		<h2>@if (this.isEditMode) { Modifier le } @else { Ajouter un } contenant</h2>
	</div>

	<form class="cb-admin-settings-containers-form" [formGroup]="this.form" (submit)="this.submit()">
		<div class="cb-admin-settings-containers-form-row">
			<mat-form-field class="cb-admin-settings-containers-form-field" appearance="outline">
				<mat-label>contenant</mat-label>
				<input
					matInput
					#container
					name="container"
					type="text"
					placeholder="product"
					formControlName="container"
					required
				/>
				<mat-error>contenant requis</mat-error>
			</mat-form-field>
		</div>

		<div class="cb-admin-settings-containers-form-row">
			<mat-form-field class="cb-admin-settings-containers-form-field" appearance="outline">
				<mat-label>poids (g)</mat-label>
				<input
					matInput
					#weight
					[min]="0"
					name="weight"
					type="number"
					placeholder="poids (g)"
					formControlName="weight"
					required
				/>

				@if(this.form.controls['weight'].hasError('required')) {
					<mat-error>poids requis</mat-error>
				}
				@if(this.form.controls['weight'].hasError('pattern')) {
					<mat-error>doit être un nombre</mat-error>
				}
				@if(this.form.controls['weight'].hasError('min')) {
					<mat-error>doit être supérieur à 0</mat-error>
				}
			</mat-form-field>
		</div>
	</form>

	<mat-dialog-actions class="cb-admin-settings-containers-buttons" align="end">
		<button
			mat-flat-button
			color="accent"
			type="submit"
			[disabled]="this.isLoading || this.form.invalid"
			(click)="this.submit()"
		>
			@if (this.isEditMode) { Modifier } @else { Ajouter }
		</button>

		<button
			mat-flat-button
			mat-dialog-close
			[disabled]="this.isLoading"
		>
			Annuler
		</button>
	</mat-dialog-actions>

</mat-dialog-content>