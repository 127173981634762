import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";

import { API_URL } from "../../../constants";
import { HEADERS } from "../headers";
import { Category } from "../../../models/category";
import { CategoryDto } from "../../../dtos/category.dto";

@Injectable({
	providedIn: "root",
})
export class ApiCategoryService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;
	private CATEGORY_PATH: string = "category";

	constructor(private http: HttpClient) {}

	public async getAll(): Promise<HttpResponse<Category[]> | undefined> {
		return this.http
			.get<Category[]>(`${this.API_URL}/${this.CATEGORY_PATH}/`, {
				headers: this.headers,
				observe: "response",
			})
			.toPromise();
	}

	public async getById(id: number): Promise<HttpResponse<Category> | undefined> {
		return this.http
			.get<Category>(`${this.API_URL}/${this.CATEGORY_PATH}/${id}`, {
				headers: this.headers,
				observe: "response",
			})
			.toPromise();
	}

	public async add(token: string, categoryDto: CategoryDto): Promise<HttpResponse<Category> | undefined> {
		return this.http
			.post<Category>(`${this.API_URL}/${this.CATEGORY_PATH}/`, categoryDto, {
				headers: this.headers.append('token', token),
				observe: "response"
			})
			.toPromise();
	}

	public async update(token: string, id: number, categoryDto: CategoryDto): Promise<HttpResponse<Category> | undefined> {
		return this.http
			.put<Category>(`${this.API_URL}/${this.CATEGORY_PATH}/${id}`, categoryDto, {
				headers: this.headers.append('token', token),
				observe: "response"
			})
			.toPromise();
	}

	public async delete(token: string, id: number): Promise<HttpResponse<void> | undefined> {
		return this.http
			.delete<void>(`${this.API_URL}/${this.CATEGORY_PATH}/${id}`, {
				headers: this.headers.append('token', token),
				observe: "response"
			})
			.toPromise();
	}
}
