import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../constants";
import { ProfileDto } from "../../../dtos/profile.dto";
import { Profile } from "../../../models/profile";
import { HEADERS } from "../headers";

@Injectable({
	providedIn: "root",
})
export class ApiProfileService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;
	private PROFILE_PATH: string = "profile";

	constructor(private http: HttpClient) {}

	public async getAll(
		token: string
	): Promise<HttpResponse<Profile[]> | undefined> {
		return this.http
			.get<Profile[]>(`${this.API_URL}/${this.PROFILE_PATH}/`, {
				headers: this.headers.append("token", token),
				observe: "response",
			})
			.toPromise();
	}

	public async getById(
		token: string,
		id: number
	): Promise<HttpResponse<Profile> | undefined> {
		return this.http
			.get<Profile>(`${this.API_URL}/${this.PROFILE_PATH}/${id}`, {
				headers: this.headers.append("token", token),
				observe: "response",
			})
			.toPromise();
	}

	public async update(
		token: string,
		id: number,
		profileDto: ProfileDto
	): Promise<HttpResponse<Profile> | undefined> {
		return this.http
			.put<Profile>(`${this.API_URL}/${this.PROFILE_PATH}/${id}`, profileDto, {
				headers: this.headers.append("token", token),
				observe: "response",
			})
			.toPromise();
	}
}
