<div class="cb-order-status-progress">
	<mat-progress-bar
		class="cb-order-status-progress-bar"
		color="primary"
		mode="determinate"
		[value]="(((this.statusLength !== 6 && this.status.id >= 6) ? this.status.id - 1 : this.status.id) / this.statusLength) * 100"
	></mat-progress-bar>

	<ul class="cb-order-status-progress-bar-texts">
		@for (item of this.length; track item) {
			<li class="cb-order-status-progress-bar-texts-text" [class]="this.status.id >= item ? 'passed' : 'not-passed'">
				<mat-icon>{{ this.status.id >= item ? 'checked' : (this.status.id === 1) ? 'close' : 'schedule' }}</mat-icon>
				<p>{{ this.status.id === item ? this.status.status : '' }}</p>
			</li>
		}
	</ul>
</div>