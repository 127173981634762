import { Component, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import 'moment/locale/fr';

import { Promotion } from '../../../_commons/models/promotion';

import { AccountService } from '../../../_commons/services/account/account.service';
import { ApiPromotionService } from '../../../_commons/services/api/api-promotion/api-promotion.service';

@Component({
	selector: 'app-admin-promo-codes-modal',
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatDatepickerModule,
		MatDialogModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatProgressSpinnerModule,
	],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
		provideMomentDateAdapter()
	],
	templateUrl: './admin-promo-codes-modal.component.html',
	styleUrl: './admin-promo-codes-modal.component.scss'
})
export class AdminPromoCodesModalComponent {

	private token: string = '';

	public isEditMode: boolean = false;
	public isLoading: boolean = false;

	public promotionForm: FormGroup = this.fb.group({
		code: [null, Validators.required],
		promotion: [null, [Validators.required, Validators.pattern(/^([0-9]*)|([0-9]*\,[0-9]*)$/), Validators.min(1)]],
		expiration: [null, Validators.required],
		usages: [null, [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.min(1)]]
	});

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<AdminPromoCodesModalComponent>,
		private sb: MatSnackBar,
		private _account: AccountService,
		private _promotion: ApiPromotionService,
		@Inject(MAT_DIALOG_DATA) private data?: Promotion
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');

		if(data) {
			this.isEditMode = true;

			this.promotionForm.setValue({
				code: data.code,
				promotion: data.promotion,
				expiration: data.expiration,
				usages: data.usages
			});
		}
	}

	public async submit(): Promise<void> {
		if(this.promotionForm.invalid)
			return;

		this.isLoading = true;
		this.promotionForm.disable();

		if(this.isEditMode) {
			try {
				const request: HttpResponse<Promotion> | undefined = await this._promotion.update(this.token, this.data!.id, this.promotionForm.value);
	
				if(request && request.ok) {
					this.sb.open(`Code ${this.promotionForm.value.code} modifié`, "Fermer", {
						duration: 3000,
						horizontalPosition: 'end',
						verticalPosition: 'top'
					});
	
					this.dialogRef.close({ success: true });
					return;
				}
			}
	
			catch (err) {}
	
			this.sb.open("Échec de la modification", "Fermer", {
				duration: 3000,
				horizontalPosition: 'end',
				verticalPosition: 'top'
			});
		}

		else {
			try {
				const request: HttpResponse<Promotion> | undefined = await this._promotion.add(this.token, this.promotionForm.value);
	
				if(request && request.ok) {
					this.sb.open(`Code ${this.promotionForm.value.code} ajouté`, "Fermer", {
						duration: 3000,
						horizontalPosition: 'end',
						verticalPosition: 'top'
					});
	
					this.dialogRef.close({ success: true });
					return;
				}
			}
	
			catch (err) {}
	
			this.sb.open("Échec de l'ajout", "Fermer", {
				duration: 3000,
				horizontalPosition: 'end',
				verticalPosition: 'top'
			});
		}

		this.isLoading = false;
		this.promotionForm.enable();
	}

}
