import { Component, ElementRef, HostListener } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { delay } from "rxjs";

import { SITEMAP, SiteMap } from "../../_commons/sitemap";
import { Link } from "../../_commons/models/link";
import { OrderedProduct } from "../../_commons/models/ordered-product";
import { User } from "../../_commons/models/user";

import { AccountService } from "../../_commons/services/account/account.service";
import { CartService } from "../../_commons/services/cart/cart.service";

@Component({
	selector: "app-header",
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		MatBadgeModule,
		MatButtonModule,
		MatIconModule
	],
	templateUrl: "./header.component.html",
	styleUrl: "./header.component.scss",
})
export class HeaderComponent {

	public cartItemsTotal: number = 0;
	public isMenuMobileOpen: boolean = false;
	public isSignedIn: boolean = false;

	public sitemap: SiteMap = SITEMAP;
	public links: Link[] = [
		{ id: 1, name: "boutique", src: SITEMAP.shop.route },
		{ id: 2, name: "engagement", href: "#engagements" },
		{ id: 3, name: "contactez-nous", src: SITEMAP.contact.route },
		// { id: 4, name: "activer ma carte", src: SITEMAP.activateCard.route },
		{ id: 5, name: "panier", src: SITEMAP.cart.route },
	];

	@HostListener("document:click", ["$event"])
	clickout(event: any) {
		if (!this.eRef.nativeElement.contains(event.target))
			this.isMenuMobileOpen = false;
	}

	constructor(
		private eRef: ElementRef,
		private _account: AccountService,
		private _cart: CartService
	) {
		this._account.currentUser.subscribe((user: User | null) => {
			this.isSignedIn = user ? true : false;
		});

		this._cart.currentCart.subscribe((orderedProducts: OrderedProduct[]) => {
			this.cartItemsTotal = orderedProducts.length ? orderedProducts
				.map((orderedProduct: OrderedProduct): number => orderedProduct.quantity)
				.reduce((a: number, b: number): number => a + b) : 0;
		});
	}

	public toggleMenuMobile(): void {
		delay(500);
		this.isMenuMobileOpen = !this.isMenuMobileOpen;
	}

}
