interface SiteMapProps { path: string; route: string; }

export interface SiteMap {

	main: SiteMapProps,
	account: SiteMapProps,
	accountDetails: SiteMapProps,
	accountDetailsResetPassword: SiteMapProps,
	accountDetailsResetPasswordEnd: SiteMapProps,
	// accountOrders: SiteMapProps,
	// accountOrder: SiteMapProps,
	orders: SiteMapProps,
	shop: SiteMapProps,
	shopProducts: SiteMapProps,
	shopProduct: SiteMapProps,
	cart: SiteMapProps,
	activateCard: SiteMapProps,
	conditions: SiteMapProps,
	legalNotice: SiteMapProps,
	contact: SiteMapProps,
	faq: SiteMapProps,

	admin: SiteMapProps,
	adminCategories: SiteMapProps,
	adminProducts: SiteMapProps,
	adminPromoCodes: SiteMapProps,
	adminOrders: SiteMapProps,
	adminUsers: SiteMapProps,
	adminMetrics: SiteMapProps,
	adminSettings: SiteMapProps,
	adminSettingsContainers: SiteMapProps,
	adminSettingsColissimo: SiteMapProps,
	adminSettingsMondialRelay: SiteMapProps,
	adminTools: SiteMapProps,

	checkout: SiteMapProps,
	checkoutReady: SiteMapProps,
	checkoutEnd: SiteMapProps,

	sign: SiteMapProps,
	signUpProcess: SiteMapProps,
	signForgotPassword: SiteMapProps,

	maintenance: SiteMapProps

}

export const SITEMAP: SiteMap = {

	main: { path: "", route: "/" },
	account: { path: "account", route: "/account" },
	accountDetails: { path: "details", route: "/account/details" },
	accountDetailsResetPassword: { path: "reset-password", route: "/account/details/reset-password" },
	accountDetailsResetPasswordEnd: { path: "reset-password-end", route: "/account/details/reset-password-end" },
	// accountOrders: { path: "orders", route: "/account/orders" },
	// accountOrder: { path: "orders/:order", route: "/account/orders" }
	orders: { path: "orders", route: "/orders" },
	shop: { path: "3-la-boutique", route: "/3-la-boutique" },
	shopProducts: { path: ":category", route: "/3-la-boutique" },
	shopProduct: { path: "3-la-boutique/products/:product", route: "/3-la-boutique/products" },
	cart: { path: "cart", route: "/cart" },
	activateCard: { path: "activate-card", route: "/activate-card" },
	conditions: { path: "conditions", route: "/conditions" },
	legalNotice: { path: "content/2-mentions-legales", route: "/content/2-mentions-legales" },
	contact: { path: "nous-contacter", route: "/nous-contacter" },
	faq: { path: "content/16-faq", route: "/content/16-faq" },

	admin: { path: "admin", route: "/admin" },
	adminCategories: { path: "categories", route: "/admin/categories" },
	adminProducts: { path: "products", route: "/admin/products" },
	adminPromoCodes: { path: "promo-code", route: "/admin/promo-code" },
	adminOrders: { path: "orders", route: "/admin/orders" },
	adminUsers: { path: "users", route: "/admin/users" },
	adminMetrics: { path: "metrics", route: "/admin/metrics" },
	adminSettings: { path: "settings", route: "/admin/settings" },
	adminSettingsContainers: { path: "settings/containers", route: "/admin/settings/containers" },
	adminSettingsColissimo: { path: "settings/colissimo", route: "/admin/settings/colissimo" },
	adminSettingsMondialRelay: { path: "settings/mondial-relay", route: "/admin/settings/mondial-relay" },
	adminTools: { path: "tools", route: "/admin/tools" },

	checkout: { path: "checkout", route: "/checkout" },
	checkoutReady: { path: "checkout-ready", route: "/checkout/checkout-ready" },
	checkoutEnd: { path: "checkout-end", route: "/checkout/checkout-end" },

	sign: { path: "sign", route: "/sign" },
	signUpProcess: { path: "sign-up-process", route: "/sign/sign-up-process" },
	signForgotPassword: { path: "forgot-password", route: "/sign/forgot-password" },

	maintenance: { path: "maintenance", route: "/maintenance" }

}