import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SITEMAP, SiteMap } from '../../_commons/sitemap';
import { SHOP_ADDRESSES } from '../../_commons/constants';
import { Address } from '../../_commons/models/address';
import { Card } from '../../_commons/models/card';
import { PaymentForm } from '../../_commons/models/payment-form';
import { User } from '../../_commons/models/user';
import { SumupProcess } from '../../_commons/models/sumup/sumup-process';
import { Promotion } from '../../_commons/models/promotion';
import { CheckoutResponse } from '../../_commons/models/checkout-response';
import { OrderContentDto } from '../../_commons/dtos/order-content.dto';

import { CartService } from '../../_commons/services/cart/cart.service';
import { CheckoutService } from '../../_commons/services/checkout/checkout.service';
import { AccountService } from '../../_commons/services/account/account.service';
import { ApiCheckoutService } from '../../_commons/services/api/api-checkout/api-checkout.service';
import { ApiPromotionService } from '../../_commons/services/api/api-promotion/api-promotion.service';

import { CbAddressSelectorComponent } from '../../_commons/components/cb-address-selector/cb-address-selector.component';
import { CbButtonComponent } from '../../_commons/components/cb-button/cb-button.component';
import { CbModalComponent } from '../../_commons/components/cb-modal/cb-modal.component';
import { CbPaymentFormComponent } from '../../_commons/components/cb-payment-form/cb-payment-form.component';
import { SummaryCartComponent } from '../summary-cart/summary-cart.component';

@Component({
	selector: 'app-checkout-ready',
	standalone: true,
	imports: [
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
		CbAddressSelectorComponent,
		CbButtonComponent,
		CbPaymentFormComponent,
		SummaryCartComponent
	],
	templateUrl: './checkout-ready.component.html',
	styleUrl: './checkout-ready.component.scss'
})
export class CheckoutReadyComponent {
	
	private token!: string;

	public sitemap: SiteMap = SITEMAP;
	public addresses: Address[] = [];
	public user!: User;

	public deliveredAddress!: Address;
	public billedAddress!: Address;
	public shopAddress: Address = SHOP_ADDRESSES[0];
	public paymentInfo: PaymentForm = { name: '', number: '', expiration: '', cvv: '' };
	public invalidPaymentForm: boolean = true;
	public sameAddress: boolean = false;
	public submitted: boolean = false;
	public promoCodeSubmitted: boolean = false;
	public promoCodeFinished: boolean = false;
	public promoCodeSuccess: boolean = false;
	public paymentInbound: boolean = false;

	public codeForm: FormGroup = this.fb.group({
		promoCode: [this._checkout.getPromotion()?.code || null, Validators.required]
	})

	constructor(
		public dialog: MatDialog,
		private fb: FormBuilder,
		private router: Router,
		private _account: AccountService,
		private _apiCheckout: ApiCheckoutService,
		private _cart: CartService,
		private _checkout: CheckoutService,
		private _promotion: ApiPromotionService
	) {
		this._account.currentUser.subscribe((user: User | null) => {
			this.user = user!;

			if(this.user)
				this.addresses = this.user.profile.addresses.filter(
					(address: Address) => address.isBilledAddress
				);

			this._checkout.currentBilledAddress.subscribe((address: Address | null) => {
				if(address)
					this.billedAddress = address;
			});

			if(!this.billedAddress)
				this.updateBilledAddress(this.addresses.find((address: Address) => address.isDefaultBilledAddress) || this.addresses[0]);
		});
		
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token!);

		this._checkout.currentDeliveredAddress.subscribe((address: Address | null) => {
			this.deliveredAddress = address!;

			if(this.sameAddress)
				this.updateBilledAddress(this.deliveredAddress);
		});

		this._checkout.currentPromotion.subscribe((promotion: Promotion | null) => {
			this.promoCodeSuccess = promotion ? true : false;
			this.promoCodeFinished = promotion ? true : false
		});

		if(!this._checkout.getPromotion())
			this.codeForm.disable();
	}

	public togglePromoCodeForm(): void {
		this.codeForm.disabled ? this.codeForm.enable() : this.codeForm.disable();
	}

	public async promoCodeSubmit(): Promise<void> {
		if(this.codeForm.invalid || this.promoCodeSuccess)
			return;

		this.promoCodeSubmitted = true;
		this.promoCodeFinished = false;

		try {
			const request: HttpResponse<Promotion> | undefined = await this._promotion.getByCode(this.token, this.codeForm.value.promoCode);

			if(request && request.ok && request.body) {
				this.promoCodeSuccess = true;
				this._checkout.setPromotion(request.body);
			}
		}

		catch (err) {}

		this.promoCodeFinished = true;
		this.promoCodeSubmitted = false;
	}

	public resetPromoCode = (): void => this._checkout.setPromotion(null);

	public updateBilledAddress(address: Address): void {
		this.billedAddress = address;
		this._checkout.setBilledAddress(address);
	}

	private getFormatedCard(paymentInfo: PaymentForm): Card {
		const expiry_month: string = paymentInfo.expiration.split('/')[0];
		const expiry_year: string = paymentInfo.expiration.split('/')[1];

		return <Card>{
			name: paymentInfo.name,
			number: paymentInfo.number,
			expiry_month,
			expiry_year,
			cvv: paymentInfo.cvv,
		}
	}

	public async submit(): Promise<void> {
		if(this.invalidPaymentForm || !this.deliveredAddress || !this.billedAddress)
			return;

		try {
			this.submitted = true;

			const orderContent: OrderContentDto = {
				promotion: this._checkout.getPromotion() || null,
				orderedProducts: this._cart.getCartItems()!,
				billedAddress: this._checkout.getBilledAddress()!,
				deliveredAddress: this._checkout.getDeliveredAddress()!
			};

			const checkoutReq: CheckoutResponse = (await this._apiCheckout.createCheckout(this.token, orderContent))?.body!;

			if(checkoutReq.amount) {
				const proceedReq: SumupProcess = (await this._apiCheckout.proceed(checkoutReq.reference, this.getFormatedCard(this.paymentInfo)))?.body!;
				const { next_step } = proceedReq;
	
				this.acsRedirect(next_step.url, next_step.payload);
			}

			else
				this.router.navigate([ SITEMAP.checkoutEnd.route ], { queryParams: { checkout_id: checkoutReq.reference }});
		}

		catch (err) {
			this.dialog.open(CbModalComponent, {
				data: {
					title: 'Échec de la procédure de paiement',
					message: 'Une erreur est survenue lors de la tentative de paiement',
					buttonText: 'fermer'
				}
			});

			this.submitted = false;
		}
	}

	private acsRedirect(url: string, obj?: any): void {
		this.submitted = true;

		const mapForm: HTMLFormElement = document.createElement('form');
		mapForm.target = "_parent";
		mapForm.method = 'POST';
		mapForm.action = url;

		if(obj)
			Object.keys(obj).forEach((param: string) => {
				const mapInput: HTMLInputElement = document.createElement('input');

				mapInput.type = 'hidden';
				mapInput.name = param;
				mapInput.setAttribute('value', obj[param]);
				mapForm.appendChild(mapInput);
			});

		document.body.appendChild(mapForm);
		mapForm.submit();
		this.submitted = false;
		this.paymentInbound = true;
	}

	public isNotKnownAddress = (): boolean => !this.addresses.map((address: Address) => JSON.stringify(this.deliveredAddress) === JSON.stringify(address)).find((v: boolean) => v === true);
	public isShopAddress = (address: Address): boolean => JSON.stringify(address) === JSON.stringify(this.shopAddress);

}
