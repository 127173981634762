import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";

import { ADMIN_LINKS } from "../admin-links";
import { SITEMAP, SiteMap } from "../../_commons/sitemap";
import { Link } from "../../_commons/models/link";

@Component({
	selector: "app-admin-path",
	standalone: true,
	imports: [
		RouterModule,
		MatIconModule
	],
	templateUrl: "./admin-path.component.html",
	styleUrl: "./admin-path.component.scss",
})
export class AdminPathComponent implements OnInit {

	public links: Link[] = ADMIN_LINKS;
	public sitemap: SiteMap = SITEMAP;
	public icon!: string;
	public url!: string;

	constructor(private router: Router) {
		this.router.events.subscribe((): void => {
			this.url = this.router.url;
			this.linkResolver();
		});
	}

	public ngOnInit(): void {
		this.linkResolver();
	}

	private linkResolver(): void {
		let link: Link | undefined = this.links.find((link: Link) => link.src === this.url);

		this.icon = link?.icon || "home";
	}

}
