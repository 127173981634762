import { RouterModule } from "@angular/router";
import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatBadgeModule } from "@angular/material/badge";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import deepEqual from "deep-equal";

import { SHOP_ADDRESSES } from "../../../_commons/constants";
import { Address } from "../../../_commons/models/address";
import { Order } from "../../../_commons/models/order";
import { Product } from "../../../_commons/models/product";

import { ToAddressPipe } from "../../../_commons/pipes/to-address/to-address.pipe";

import { CbButtonComponent } from "../../../_commons/components/cb-button/cb-button.component";
import { CbOrderStatusProgressComponent } from "../../../_commons/components/cb-order-status-progress/cb-order-status-progress.component";
import { OrderItemProductComponent } from "./order-item-product/order-item-product.component";

@Component({
	selector: "app-order-item",
	standalone: true,
	imports: [
		MatExpansionModule,
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatBadgeModule,
		ToAddressPipe,
		CbButtonComponent,
		OrderItemProductComponent,
		CbOrderStatusProgressComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	templateUrl: "./order-item.component.html",
	styleUrl: "./order-item.component.scss",
})
export class OrderItemComponent {

	@Input() public order!: Order;
	@Input() public selectedWeight!: number;

	public customCollapsedHeight: string = "226px";
	public customExpandedHeight: string = "226px";
	public displayProductItemInfo: boolean = false;

	constructor() {}

	public getProductWeight = (product: Product): number | null =>
		product.prices[0].weight || null;

	public isShopAddress = (address: string): boolean => deepEqual(<Address>JSON.parse(address), SHOP_ADDRESSES[0]);
}
