import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";

import { API_URL } from "../../../constants";
import { HEADERS } from "../headers";
import { Product } from "../../../models/product";
import { ProductDto } from "../../../dtos/product.dto";

@Injectable({
	providedIn: "root",
})
export class ApiProductService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;
	private PRODUCT_PATH: string = "product";

	constructor(private http: HttpClient) {}

	public async getAll(): Promise<HttpResponse<Product[]> | undefined> {
		return this.http
			.get<Product[]>(`${this.API_URL}/${this.PRODUCT_PATH}/`, {
				headers: this.headers,
				observe: "response",
			})
			.toPromise();
	}

	public async getById(id: number): Promise<HttpResponse<Product> | undefined> {
		return this.http
			.get<Product>(`${this.API_URL}/${this.PRODUCT_PATH}/${id}`, {
				headers: this.headers,
				observe: "response",
			})
			.toPromise();
	}

	public async add(token: string, productDto: ProductDto): Promise<HttpResponse<Product> | undefined> {
		return this.http
			.post<Product>(`${this.API_URL}/${this.PRODUCT_PATH}/`, productDto, {
				headers: this.headers.append('token', token),
				observe: "response"
			})
			.toPromise();
	}

	public async update(token: string, id: number, productDto: ProductDto): Promise<HttpResponse<Product> | undefined> {
		return this.http
			.put<Product>(`${this.API_URL}/${this.PRODUCT_PATH}/${id}`, productDto, {
				headers: this.headers.append('token', token),
				observe: "response"
			})
			.toPromise();
	}

	public async delete(token: string, id: number): Promise<HttpResponse<void> | undefined> {
		return this.http
			.delete<void>(`${this.API_URL}/${this.PRODUCT_PATH}/${id}`, {
				headers: this.headers.append('token', token),
				observe: "response"
			})
			.toPromise();
	}
}
