import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ProductContainer } from '../../../../_commons/models/product-container';

import { AccountService } from '../../../../_commons/services/account/account.service';
import { ProductContainerService } from '../../../../_commons/services/product-container/product-container.service';

@Component({
	selector: 'app-admin-settings-containers-modal',
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatDialogModule,
		MatFormFieldModule,
		MatInputModule,
		MatProgressSpinnerModule,
	],
	templateUrl: './admin-settings-containers-modal.component.html',
	styleUrl: './admin-settings-containers-modal.component.scss'
})
export class AdminSettingsContainersModalComponent {

	private token: string = '';

	public isEditMode: boolean = false;
	public isLoading: boolean = false;

	public form: FormGroup = this.fb.group({
		container: [null, Validators.required],
		weight: [null, [Validators.required, Validators.min(0)]]
	});

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<AdminSettingsContainersModalComponent>,
		private sb: MatSnackBar,
		private _account: AccountService,
		private _productContainer: ProductContainerService,
		@Inject(MAT_DIALOG_DATA) private data?: ProductContainer
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');

		if(data) {
			this.isEditMode = true

			this.form.setValue({
				container: data.container,
				weight: data.weight
			});
		}
	}

	public async submit(): Promise<void> {
		if(this.form.invalid)
			return;

		this.isLoading = true;
		this.form.disable();

		if(this.isEditMode) {
			try {
				const request: ProductContainer | null = await this._productContainer.updateProductContainers({ ...this.form.value });

				if(request) {
					this.sb.open(`${this.form.value.container} a été modifié`, "Fermer", {
						duration: 3000,
						horizontalPosition: 'end',
						verticalPosition: 'top'
					});

					return this.dialogRef.close({ success: true });
				}
			}

			catch (err) {}

			this.sb.open("Échec de la modification", "Fermer", {
				duration: 3000,
				horizontalPosition: 'end',
				verticalPosition: 'top'
			});
		}

		else {
			try {
				const request: ProductContainer | null = await this._productContainer.addProductContainer({ ...this.form.value });
	
				if(request) {
					this.sb.open(`${this.form.value.container} a été ajoutée`, "Fermer", {
						duration: 3000,
						horizontalPosition: 'end',
						verticalPosition: 'top'
					});
	
					this.dialogRef.close({ success: true });
					return;
				}
			}
	
			catch(err) {}
	
			this.sb.open("Échec de l'ajout", "Fermer", {
				duration: 3000,
				horizontalPosition: 'end',
				verticalPosition: 'top'
			});
		}

		this.isLoading = false;
		this.form.enable();
	}

}
