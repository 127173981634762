<h2 class="cb-title">Outils</h2>

<div class="cb-admin-tools">
	<button
		mat-flat-button
		class="cb-admin-tools-button"
		(click)="this.openColissimoModal()"
	>
		<mat-icon>qr_code_2</mat-icon>
		<p>Generate Colissimo</p>
	</button>
</div>