import { Component } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { SITEMAP, SiteMap } from "../../../../_commons/sitemap";

import { CbButtonComponent } from "../../../../_commons/components/cb-button/cb-button.component";

@Component({
	selector: "app-account-details-reset-password",
	standalone: true,
	imports: [
		RouterModule,
		CbButtonComponent
	],
	templateUrl: "./account-details-reset-password.component.html",
	styleUrl: "./account-details-reset-password.component.scss",
})
export class AccountDetailsResetPasswordComponent {

	public sitemap: SiteMap = SITEMAP;

}
