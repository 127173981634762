import { Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";

import { SITEMAP, SiteMap } from "../../../_commons/sitemap";
import { Address } from "../../../_commons/models/address";
import { User } from "../../../_commons/models/user";

import { AccountService } from "../../../_commons/services/account/account.service";

import { CbButtonComponent } from "../../../_commons/components/cb-button/cb-button.component";

@Component({
	selector: "app-account-index",
	standalone: true,
	imports: [RouterModule, CbButtonComponent],
	templateUrl: "./account-index.component.html",
	styleUrl: "./account-index.component.scss",
})
export class AccountIndexComponent implements OnInit {

	public sitemap: SiteMap = SITEMAP;
	public user!: User;
	public address!: Address;

	public isDisconnecting: boolean = false;

	constructor(
		private router: Router,
		private _account: AccountService
	) {}

	public async ngOnInit(): Promise<void> {
		this._account.currentUser.forEach((user: User | null) => {
			this.user = user!;

			if (this.user)
				this.address = this.user.profile.addresses.find(
					(address) => address.isDefault
				)!;
		});
	}

	public async signOut(): Promise<void> {
		this.isDisconnecting = true;

		await this._account.logout();
		this._account.resetAccount();
		this.router.navigate([SITEMAP.main.route]);
	}

}
