import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Promotion } from '../../_commons/models/promotion';

import { AccountService } from '../../_commons/services/account/account.service';
import { ApiPromotionService } from '../../_commons/services/api/api-promotion/api-promotion.service';

import { ToDatePipe } from '../../_commons/pipes/to-date/to-date.pipe';

import { AdminPromoCodesModalComponent } from './admin-promo-codes-modal/admin-promo-codes-modal.component';

@Component({
	selector: 'app-admin-promo-code',
	standalone: true,
	imports: [
		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatTooltipModule,
		ToDatePipe
	],
	templateUrl: './admin-promo-codes.component.html',
	styleUrl: './admin-promo-codes.component.scss'
})
export class AdminPromoCodeComponent implements OnInit {

	private token: string = '';

	public displayedColumns: string[] = ['id', 'code', 'promotion', 'expiration', 'usages', 'uses', 'actions'];
	public promotions: Promotion[] = [];

	public isLoading: boolean = false;

	constructor(
		private dialog: MatDialog,
		private sb: MatSnackBar,
		private _account: AccountService,
		private _promotion: ApiPromotionService
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');
	}

	public async ngOnInit(): Promise<void> {
		this.isLoading = true;
		this.promotions = (await this._promotion.getAll(this.token))?.body || [];
		this.isLoading = false;
	}

	public openDialog(promotion?: Promotion): void {
		this.dialog.open(AdminPromoCodesModalComponent, {
			disableClose: true,
			data: promotion
		})
		.afterClosed().subscribe(async (data) => {
			if(data.success)
				this.promotions = (await this._promotion.getAll(this.token))?.body || [];
		});
	}

	public async delete(promotion: Promotion): Promise<void> {
		try {
			const request: HttpResponse<void> | undefined = await this._promotion.delete(this.token, promotion.id);

			if(request && request.ok) {
				this.sb.open(`Code ${promotion.code} supprimée`, "Fermer", {
					duration: 3000,
					horizontalPosition: 'end',
					verticalPosition: 'top'
				});

				this.promotions = (await this._promotion.getAll(this.token))?.body || [];
				return;
			}
		}

		catch (err) {}

		this.sb.open("Échec de la suppression", "Fermer", {
			duration: 3000,
			horizontalPosition: 'end',
			verticalPosition: 'top'
		});
	}

}
