<div class="cb-home-hero">
	<img class="cb-home-hero-img" src="/assets/imgs/HERO/hero.png" />

	<div class="cb-mate">
		<svg width="750" height="691" viewBox="0 0 750 691" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M749.546 0C752.308 44.7532 741.075 90.2937 738.118 102.282C732.234 126.158 714.131 187.188 665.198 237.92C618.667 286.204 572.277 296.07 509.461 309.429C471.688 317.462 427.974 326.758 374.719 346.431C336.623 360.492 227.348 401.921 134.825 487.972C61.3176 556.325 31.5105 622.026 7.42554 675.114C4.96167 680.545 2.55762 685.844 0.172852 691H0V0H749.546Z"
				fill="#FEC974"
			/>
		</svg>
	</div>

	<div class="cb-home-hero-ctnt">
		<img class="cb-home-hero-label" src="/assets/imgs/HERO/label.png" alt="labels" />
		<h1 class="cb-title">carrément<br/>bon</h1>

		<div class="cb-btn-algn">
			<app-cb-button [routerLink]="[this.sitemap.shop.route]" text="La boutique" color="primary"></app-cb-button>
		</div>
	</div>
</div>

<div class="cb-home-section-cta" id="engagements">
	<div class="cb-home-section-cta-ctnr">
		<img class="cb-img" src="../../../assets/imgs/HERO/heart.png" alt="heart" />

		<div class="cb-home-section-position">
			<h2 class="cb-title">Je craque pour les biscuits</h2>
			<div class="cb-pseudoelement"></div>
			<p class="cb-section-paragraphe">Des biscuits fabriqués avec amour dans la campagne de Midi Pyrénées.</p>

			<div class="cb-btn-algn">
				<app-cb-button [routerLink]="[this.sitemap.shop.route]" text="La boutique" color="primary"></app-cb-button>
			</div>
		</div>

		<img class="cb-img" src="../../../assets/imgs/HERO/crackers.png" alt="crackers" />
	</div>
</div>

<div class="cb-home-section-about">
	<div class="cb-home-section-about-ctnr">
		<div class="cb-block">
			<mat-icon class="cb-icon">compost</mat-icon>
			<h3 class="cb-title">Agriculture</h3>
			<div class="cb-paragraphe">
				L'agriculture respectueuse de l'environnement prend soin de votre santé et de celle des producteurs. S'engager, c'est rémunérer à sa juste valeur le travail de la terre.
			</div>
		</div>

		<div class="cb-block">
			<mat-icon class="cb-icon">cookie</mat-icon>
			<h3 class="cb-title">Artisan</h3>
			<div class="cb-paragraphe">
				L'artisan met en valeur les bons produits et transmet les recettes et le savoir-faire traditionnel. Respecter le produit et le consommateur, c'est pour nous zéro colorant artificiel, additif
				ou conservateur.
			</div>
		</div>

		<div class="cb-block">
			<mat-icon class="cb-icon">recycling</mat-icon>
			<h3 class="cb-title">Être consommacteur</h3>
			<div class="cb-paragraphe">
				Les modes de production, de consommation, les réseaux de distribution impactent notre environnement et notre société. S'engager, c'est choisir ses réseaux d'approvisionnement, mieux consommer,
				refuser le sur-emballage, ne pas gaspiller...
			</div>
		</div>
	</div>
</div>

<div class="cb-home-section-picture">
	<div class="cb-home-section-picture-ctnr">
		<div class="cb-img-ctnr">
			<img class="cb-img cb-christine" src="../../../assets/imgs/HERO/christine.png" alt="" />
			<img class="cb-img" src="../../../assets/imgs/HERO/POULET.png" alt="" />
		</div>
		<div class="cb-img-ctnr">
			<img class="cb-img cb-biscuit" src="../../../assets/imgs/HERO/biscuit torchon.png" alt="" />
		</div>
	</div>
</div>

<div class="cb-home-section-social">
	<div class="cb-home-section-social-ctnr">
		<div class="cb-title">#carrementbonbiscuits</div>
		<div class="cb-socials">
			<a href="https://www.instagram.com/carrementbonbiscuits/?hl=fr" target="_blank">
				<mat-icon class="cb-social-icon" svgIcon="instagram"></mat-icon>
			</a>
			<a href="https://www.facebook.com/carrementbonbiscuits" target="_blank">
				<mat-icon class="cb-social-icon" svgIcon="facebook"></mat-icon>
			</a>
		</div>
	</div>
</div>

<div class="cb-home-section-newsletter">
	<div class="cb-home-section-newsletter-ctnr">
		<h2 class="cb-title">Rejoignez notre newsletter</h2>
		<h3 class="cb-subtitle">ne manquez pas</h3>
		<div class="cb-texts-ctnr">
			<div class="cb-text-ctnr">
				<div class="cb-text">les nouveaux produits</div>
				<mat-icon class="cb-check-icon">check</mat-icon>
			</div>
			<div class="cb-text-ctnr">
				<div class="cb-text">les nouvelles recettes</div>
				<mat-icon class="cb-check-icon">check</mat-icon>
			</div>
			<div class="cb-text-ctnr">
				<div class="cb-text">les conseils nutritions</div>
				<mat-icon class="cb-check-icon">check</mat-icon>
			</div>
		</div>
		<form class="cb-newsletter" [formGroup]="this.newsLetterForm" (submit)="this.subscribeToNewsLetter()">
			<mat-form-field class="cb-newsletter-form-field" appearance="outline">
				<mat-label>adresse e-mail</mat-label>
				<input matInput type="email" placeholder="adresse e-mail" formControlName="email" />
				<mat-icon matSuffix>mail</mat-icon>
			</mat-form-field>

			<div class="cb-btn-algn">
				<app-cb-button class="cb-sign-in-submit" color="primary" text="s'inscrire" type="submit"></app-cb-button>
			</div>
		</form>
	</div>
</div>
