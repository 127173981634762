<div class="cb-orders-order-details">

	@if (this.isLoading) {
		<div class="cb-orders-order-details-loading">
			<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
		</div>
	}

	<app-cb-order-status-progress
		class="cb-orders-order-details-progress"
		[status]="order.status"
		[statusLength]="this.isShopAddress(this.order.deliveredAddress) ? 6 : 7"
	></app-cb-order-status-progress>

	<div class="cb-orders-order-details-left">
		@if (order.user; as user) {
			<h3>Utilisateur</h3>
			<mat-card class="cb-orders-order-details-user">
				<mat-card-header>
					<mat-icon mat-card-avatar>person</mat-icon>
					<mat-card-title>{{ user.profile.firstName }} {{ user.profile.lastName }}</mat-card-title>
					<mat-card-subtitle>{{ user.email }}</mat-card-subtitle>
				</mat-card-header>
			</mat-card>
		}

		@if (order.promoCode; as promoCode) {
			<h3>Code promo</h3>
			<p>Code promo utilisé: {{ promoCode }}</p>
		}

		<div class="cb-orders-order-details-addresses">
			@if (order.deliveredAddress | toAddress; as deliveredAddress) {
				<div class="cb-orders-order-details-addresses-address">
					<h3>Adresse de livraison</h3>
					<p>{{ deliveredAddress.firstName }} {{ deliveredAddress.lastName }}</p>
					<p>{{ deliveredAddress.street }}</p>
					<p>{{ deliveredAddress.city }} {{ deliveredAddress.zip }} {{ deliveredAddress.country }}</p>
					<p>{{ deliveredAddress.phone }}</p>
					@if (deliveredAddress.MRID) { <p>{{ deliveredAddress.MRID }}</p> }
				</div>
			}

			@if (order.billedAddress | toAddress; as billedAddress) {
				<div class="cb-orders-order-details-addresses-address">
					<h3>Adresse de facturation</h3>
					<p>{{ billedAddress.firstName }} {{ billedAddress.lastName }}</p>
					<p>{{ billedAddress.street }}</p>
					<p>{{ billedAddress.city }} {{ billedAddress.zip }} {{ billedAddress.country }}</p>
					<p>{{ billedAddress.phone }}</p>
				</div>
			}
		</div>
	</div>

	<div class="cb-orders-order-details-right">
		<mat-form-field appearance="outline">
			<mat-label>Statut</mat-label>
			<mat-select
				(valueChange)="this.updateOrderStatus($event);"
				[value]="order.status.id"
			>
				@for (orderStatus of this.orderStatuses; track orderStatus) {
					@if (
						orderStatus.id <= (this.isShopAddress(this.order.deliveredAddress) ? 6 : 8)
						&& !(orderStatus.id === 6 && orderStatus.id <= (this.isShopAddress(this.order.deliveredAddress) ? 0 : 8))
					) {
						<mat-option [value]="orderStatus.id">{{ orderStatus.status }}</mat-option>
					}
				}
			</mat-select>
		</mat-form-field>
	</div>
</div>