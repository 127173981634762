<div class="cb-admin-home">
	<div class="cb-buttons">

		@for (link of this.links; track links) {
			@if (link.id > 1) {
				<button
					mat-flat-button
					class="cb-button"
					[matBadge]="(link.name === 'commandes' && this.orderRemains.length) ? this.orderRemains.length : ''"
					[routerLink]="[ link.src ]"
				>
					<mat-icon class="cb-button-icon">{{ link.icon }}</mat-icon>
					<span class="cb-button-text">@for(char of link.name; track $index) {{{ $index === 0 ? char.toUpperCase() : char }}}</span>
				</button>
			}
		}

	</div>
</div>