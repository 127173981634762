import { Component, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Category } from '../../../_commons/models/category';
import { Price } from '../../../_commons/models/price';
import { Product } from '../../../_commons/models/product';
import { ProductDto } from '../../../_commons/dtos/product.dto';

import { AccountService } from '../../../_commons/services/account/account.service';
import { CategoryService } from '../../../_commons/services/category/category.service';
import { ProductContainerService } from '../../../_commons/services/product-container/product-container.service';
import { ProductContainer } from '../../../_commons/models/product-container';
import { ApiProductService } from '../../../_commons/services/api/api-product/api-product.service';

@Component({
	selector: 'app-admin-products-modal',
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDialogModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatSelectModule,
	],
	templateUrl: './admin-products-modal.component.html',
	styleUrl: './admin-products-modal.component.scss'
})
export class AdminProductsModalComponent {

	private pattern: RegExp = /^([0-9]*)|([0-9]*\,[0-9]*)$/;
	private token: string = '';

	public categories: Category[] = [];
	public productContainers: ProductContainer[] = [];
	public priceForms: FormGroup[] = [];

	public isEditMode: boolean = false;
	public isLoading: boolean = false;
	public slugString: string = '';

	public productForm: FormGroup = this.fb.group({
		category: [null, Validators.required],
		name: [null, Validators.required],
		slug: [null, [ Validators.required, Validators.pattern(/^([0-9]|[a-z]|[A-Z]|\-)*$/) ]],
		img: [null, Validators.required],
		description: [null, Validators.required],
		isAvailable: [true]
	});

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<AdminProductsModalComponent>,
		private sb: MatSnackBar,
		private _account: AccountService,
		private _category: CategoryService,
		private _product: ApiProductService,
		private _productContainer: ProductContainerService,
		@Inject(MAT_DIALOG_DATA) private data: Product
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');
		this._productContainer.currentProductContainers.subscribe((productContainers: ProductContainer[]) => {
			this.productContainers = productContainers;
			this.isLoading = this.productContainers.length === 0 ? true : false;
		});
		this._category.currentCategories.subscribe((categories: Category[]) => {
			this.categories = categories;
			this.isLoading = this.categories.length === 0 ? true : false;
		});

		if(data) {
			this.isEditMode = true;

			this.productForm.setValue({
				category: data.category.id,
				name: data.name,
				slug: data.slug,
				img: data.img,
				description: data.description,
				isAvailable: data.isAvailable
			});

			this.slugString = data.slug;

			data.prices.map((price: Price) => {
				this.priceForms.push(this.fb.group({
					id: price.id,
					container: [price.container.id, Validators.required],
					unit: [price.unit, Validators.required],
					value: [price.value, [Validators.required, Validators.pattern(this.pattern), Validators.min(1)]],
					weight: [price.weight, [Validators.required, Validators.pattern(this.pattern), Validators.min(1)]],
					isVisible: price.isVisible
				}));
			});
		}
	}

	public updateSlug(name: string): void {
		this.slugString = name
			.toLowerCase()
			.replace(/[*-+ ?!,/=.';]+/g, '-')
			.replace(/[éèêë]/g, 'e')
			.replace(/[àâä]/g, 'a')
			.replace(/[îï]/g, 'i')
			.replace(/[ôö]/g, 'o')
			.replace(/[üû]/g, 'u');
	}

	public deletePrice = (index: number): FormGroup<any>[] => this.priceForms.splice(index, 1);

	public addPrice(): void {
		this.priceForms.push(this.fb.group({
			container: [this.productContainers[0].id, Validators.required],
			unit: ['EUR', Validators.required],
			value: [null, [Validators.required, Validators.pattern(this.pattern), Validators.min(1)]],
			weight: [null, [Validators.required, Validators.pattern(this.pattern), Validators.min(1)]],
			isVisible: true
		}));
	}

	public isInvalidForms(): boolean {
		if(
			(this.priceForms.filter((fg: FormGroup) => fg.invalid).length || this.productForm.invalid)
			|| (this.priceForms.length && this.priceForms.map((fg: FormGroup) => fg.value.isVisible).every((v: boolean) => !v))
		)
			return true;

		return false;
	}

	private wrapper(): ProductDto {
		return {
			...this.productForm.value,
			category: this.categories.find((c) => c.id === this.productForm.value.category)!,
			prices: this.priceForms.map((item: FormGroup) => {
				return {
					...item.value,
					container: this.productContainers.find((pc) => pc.id === item.value.container)!
				};
			})
		};
	}

	public async submit(): Promise<void> {
		if(this.isInvalidForms())
			return;

		this.isLoading = true;
		this.productForm.disable();

		if(this.isEditMode) {
			try {
				const request: HttpResponse<Product> | undefined = await this._product.update(this.token, this.data.id, this.wrapper());

				if(request && request.ok) {
					this.sb.open(`${this.productForm.value.name} a été modifié`, "Fermer", {
						duration: 3000,
						horizontalPosition: 'end',
						verticalPosition: 'top'
					});

					return this.dialogRef.close({ success: true });
				}
			}

			catch (err) {}

			this.sb.open("Échec de la modification", "Fermer", {
				duration: 3000,
				horizontalPosition: 'end',
				verticalPosition: 'top'
			});
		}

		else {
			try {
				const request: HttpResponse<Product> | undefined = await this._product.add(this.token, this.wrapper());
	
				if(request && request.ok) {
					this.sb.open(`${this.productForm.value.name} a été ajouté`, "Fermer", {
						duration: 3000,
						horizontalPosition: 'end',
						verticalPosition: 'top'
					});
	
					return this.dialogRef.close({ success: true });
				}
			}
	
			catch (err) {}
	
			this.sb.open("Échec de l'ajout", "Fermer", {
				duration: 3000,
				horizontalPosition: 'end',
				verticalPosition: 'top'
			});
		}

		this.isLoading = false;
		this.productForm.enable();
	}

}
