import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { SITEMAP, SiteMap } from '../../_commons/sitemap';
import { Link } from '../../_commons/models/link';
import { ADMIN_SETTINGS_LINKS } from './admin-settings-links';

@Component({
	selector: 'app-admin-settings',
	standalone: true,
	imports: [
		RouterModule,
		MatButtonModule,
		MatIconModule
	],
	templateUrl: './admin-settings.component.html',
	styleUrl: './admin-settings.component.scss'
})
export class AdminSettingsComponent {

	public links: Link[] = ADMIN_SETTINGS_LINKS;
	
}
