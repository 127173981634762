import { Component } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';

import { User } from '../../_commons/models/user';

import { ToDatePipe } from "../../_commons/pipes/to-date/to-date.pipe";

import { Success } from '../../_commons/models/success';
import { ApiAuthService } from '../../_commons/services/api/api-auth/api-auth.service';
import { SearchUserPipe } from '../../_commons/pipes/search-user/search-user.pipe';
import { UserService } from '../../_commons/services/user/user.service';

import { AdminUsersItemComponent } from './admin-users-item/admin-users-item.component';

@Component({
	selector: 'app-admin-users',
	standalone: true,
	templateUrl: './admin-users.component.html',
	styleUrl: './admin-users.component.scss',
	imports: [
		MatCheckboxModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		MatIconModule,
		SearchUserPipe,
		ToDatePipe,
		AdminUsersItemComponent,
	]
})
export class AdminUsersComponent {

	public users!: User[];

	public isLoading: boolean = false;

	constructor(
		private _auth: ApiAuthService,
		private _user: UserService,
		private sb: MatSnackBar
	) {
		this.getUsers();

		this._user.currentUsers.subscribe((users: User[]) => this.users = users);
	}

	private async getUsers(): Promise<void> {
		this.isLoading = true;
		this.users = await this._user.getUsers();
		this.isLoading = false;
	}

	public async resetUserPassword(email: string): Promise<void> {

		try {
			const request: Success = (await this._auth.resetPassword({ email: email }))?.body!;

			if(request.success)
				this.sb.open(`Nouveau mot de passe envoyé à ${email}`, "Fermer", {
					duration: 3000,
					horizontalPosition: 'end',
					verticalPosition: 'top'
				});
		}

		catch(err) {
			this.sb.open("Une erreur s'est produite pendant la procédure", "Fermer", {
				duration: 3000,
				horizontalPosition: 'end',
				verticalPosition: 'top'
			});
		}

	}

}
