<div class="cb-shop">

	<aside class="cb-shop-categories">
		<app-categories [categories]="this.categories"></app-categories>
	</aside>

	<aside class="cb-shop-products">
		<router-outlet></router-outlet>
	</aside>

</div>