import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
	selector: 'app-maintenance',
	standalone: true,
	imports: [],
	templateUrl: './maintenance.component.html',
	styleUrl: './maintenance.component.scss'
})
export class MaintenanceComponent {

	public image: string = 'https://i1.sndcdn.com/artworks-000127781016-42ddp5-t500x500.jpg';
	public title: string = 'Nous serons bientôt de retour';
	public message: string = "Une maintenance est en cours sur notre site actuellement et nous reviendrons très bientôt. Merci pour votre patience.";

	constructor(private route: ActivatedRoute) {
		this.route.params.forEach((value: Params): void => {
			switch(value['reason']) {
				case 'authent':
					this.image = 'https://m.media-amazon.com/images/I/41dFyzEHURL._AC_SY580_.jpg';
					this.title = 'Erreur de Token';
					this.message = "Le Token présent sur votre client est manquant ou incorrect. Si le problème persiste, veuillez contactez le support à l'adresse suivante: contact@asc-it.fr";
					break;
			}
		});
	}

}
