import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ProductContainer } from '../../../_commons/models/product-container';

import { ProductContainerService } from '../../../_commons/services/product-container/product-container.service';

import { AdminSettingsContainersModalComponent } from './admin-settings-containers-modal/admin-settings-containers-modal.component';

@Component({
	selector: 'app-admin-settings-containers',
	standalone: true,
	imports: [
		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatTooltipModule
	],
	templateUrl: './admin-settings-containers.component.html',
	styleUrl: './admin-settings-containers.component.scss'
})
export class AdminSettingsContainersComponent {

	public productContainers: ProductContainer[] = [];

	public displayedColumns: string[] = [
		"id",
		"container",
		"weight",
		"actions"
	];

	public isLoading: boolean = false;

	constructor(
		private dialog: MatDialog,
		private sb: MatSnackBar,
		private _productContainer: ProductContainerService
	) {
		this._productContainer.currentProductContainers.subscribe((productContainers: ProductContainer[]) => {
			this.isLoading = true;
			this.productContainers = productContainers;
			this.isLoading = false;
		});
	}

	public openDialog(productContainer?: ProductContainer): void {
		this.dialog.open(AdminSettingsContainersModalComponent, {
			disableClose: true,
			data: productContainer
		})
		.afterClosed().subscribe(async (data) => {
			if(data.success)
				this.productContainers = await this._productContainer.getProductContainers() || [];
		});
	}

	public async delete(productContainer: ProductContainer): Promise<void> {
		try {
			const request: boolean = await this._productContainer.removeProductContainer(productContainer.id);

			if(request) {
				this.sb.open(`Code ${productContainer.container} supprimée`, "Fermer", {
					duration: 3000,
					horizontalPosition: 'end',
					verticalPosition: 'top'
				});

				return;
			}
		}

		catch (err) {}

		this.sb.open("Échec de la suppression", "Fermer", {
			duration: 3000,
			horizontalPosition: 'end',
			verticalPosition: 'top'
		});
	}

}
