import { Pipe, PipeTransform } from '@angular/core';

import { Order } from '../../models/order';

@Pipe({
	name: 'searchOrder',
	standalone: true
})
export class SearchOrderPipe implements PipeTransform {

	public transform = (value: Order[], id: string): Order[] =>
		value.filter((v: Order) => v.id.toString().includes(id));

}
