import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ColissimoWeight } from '../../models/colissimo-weight';

import { ApiColissimoPriceService } from '../api/api-colissimo-price/api-colissimo-price.service';
import { AccountService } from '../account/account.service';

@Injectable({
	providedIn: 'root'
})
export class ColissimoPriceService {

	private token: string = '';

	private approvalColissimoPrices: BehaviorSubject<ColissimoWeight[]> = new BehaviorSubject<ColissimoWeight[]>([]);
	public currentColissimoPrices: Observable<ColissimoWeight[]> = this.approvalColissimoPrices.asObservable();

	constructor(
		private _colissimoPrice: ApiColissimoPriceService,
		private _account: AccountService,
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');
	}

	public async getColissimoPrices(): Promise<ColissimoWeight[]> {
		if(!this.approvalColissimoPrices.getValue().length) {
			const orders: ColissimoWeight[] = (await this._colissimoPrice.getAll())?.body || [];
			this.approvalColissimoPrices.next(orders);
		}

		return this.approvalColissimoPrices.getValue();
	}
}
