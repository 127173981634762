@if(this.orderedProduct) {
	<div class="cb-cart-item">
		<div
			class="cb-cart-item-image"
			[style.background-image]="'url({0})' | replace : '{0}' : this.orderedProduct.product.img || ''"
			[style.background-size]="this.orderedProduct.product.isBox ? 'cover' : ''"
			[routerLink]="[this.sitemap.shopProduct.route, this.orderedProduct.product.id]"
		></div>

		<div class="cb-cart-item-details">
			<div class="cb-cart-item-details-cell">
				<a class="cb-cart-item-title" [routerLink]="[this.sitemap.shopProduct.route, orderedProduct.product.id]">
					{{ this.orderedProduct.product.name }}
				</a>
			</div>

			<div class="cb-cart-item-details-cell">
				@if(this.orderedProduct.product.description; as description) {
					<a class="cb-cart-item-description" [routerLink]="[this.sitemap.shopProduct.route, orderedProduct.product.id]">
						{{ (description.length || 0) > 109 ? description.slice(0, description.lastIndexOf(".", 109)) + "..." : description }}
					</a>
				}
			</div>

			<div class="cb-cart-item-details-cell">
				<div class="cb-cart-item-details-remove">
					<button mat-icon-button (click)="this.removeProduct(this.orderedProduct)">
						<mat-icon>delete_forever</mat-icon>
					</button>
				</div>

				<div class="cb-cart-item-details-quantity">
					<button mat-icon-button (click)="this.decreaseQuantity(this.orderedProduct)">
						<mat-icon>do_not_disturb_on</mat-icon>
					</button>
					<span class="cb-cart-item-details-quantity-value">{{ this.orderedProduct.quantity }}</span>
					<button mat-icon-button (click)="this.increaseQuantity(this.orderedProduct)">
						<mat-icon>add_circle</mat-icon>
					</button>
				</div>
			</div>
		</div>

		<div class="cb-cart-item-details cb-cart-item-details-mobile">
			<div class="cb-cart-item-details-cell">
				@if(this.orderedProduct.product.description; as description){
					<a class="cb-cart-item-description" [routerLink]="[this.sitemap.shopProduct.route, orderedProduct.product.id]">
						{{ (description.length || 0) > 109 ? description.slice(0, description.lastIndexOf(".", 109)) + "..." : description }}
					</a>
				}
			</div>
		</div>

		<div class="cb-cart-item-recap">
			@if(!this.orderedProduct.product.isBox){
				<div class="cb-cart-item-recap-group">
					<span>Poids</span>
					<span>{{ (this.orderedProduct.price.weight + this.orderedProduct.price.container.weight) * this.orderedProduct.quantity }} g</span>
				</div>
			}

			<div class="cb-cart-item-recap-group">
				<span>Quantité</span>
				<span>{{ this.orderedProduct.quantity }}</span>
			</div>
			<div class="cb-cart-item-recap-group">
				<span>Prix</span>
				<span>{{ (this.orderedProduct.price.value * this.orderedProduct.quantity) | toPrice }} €</span>
			</div>
		</div>
	</div>
}
