import { Component, Input, OnInit } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';

import { OrderStatus } from '../../models/order-status';
import { OrderStatusService } from '../../services/order-status/order-status.service';

@Component({
	selector: 'app-cb-order-status-progress',
	standalone: true,
	imports: [
		MatProgressBarModule,
		MatIconModule
	],
	templateUrl: './cb-order-status-progress.component.html',
	styleUrl: "./cb-order-status-progress.component.scss"
})
export class CbOrderStatusProgressComponent implements OnInit {

	@Input() public status!: OrderStatus;
	@Input() public statusLength: number = 0;

	private orderStatuses: OrderStatus[] = [];

	public length: number[] = [];

	constructor(private _orderStatus: OrderStatusService) {}

	public ngOnInit(): void {
		this._orderStatus.currentOrderStatuses.subscribe((orderStatuses: OrderStatus[]) => {
			this.orderStatuses = orderStatuses;
			this.length = this.orderStatuses
				.filter((orderStatus: OrderStatus) => (this.statusLength === 6 && orderStatus.id <= this.statusLength) || (this.statusLength === 7 && orderStatus.id !== 6))
				.map((orderStatus: OrderStatus) => orderStatus.id);
		});
	}

}
