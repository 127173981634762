<mat-dialog-content class="cb-admin-promo-codes-modal">

	@if (this.isLoading) {
		<div class="cb-admin-promo-codes-modal-loading">
			<mat-progress-spinner
				diameter="48"
				mode="indeterminate"
			></mat-progress-spinner>
		</div>
	}

	<div class="cb-admin-promo-codes-heading">
		<h2>@if (this.isEditMode) { Modifier le } @else { Ajouter un } code promo</h2>
	</div>

	<form class="cb-admin-promo-codes-form" [formGroup]="this.promotionForm" (submit)="this.submit()">
		<div class="cb-admin-promo-codes-form-row">
			<mat-form-field class="cb-admin-promo-codes-form-field" appearance="outline">
				<mat-label>code</mat-label>
				<input
					matInput
					#code
					name="code"
					type="text"
					placeholder="XXXXXXXX"
					formControlName="code"
					required
				/>
				<mat-error>code requis</mat-error>
			</mat-form-field>
		</div>

		<div class="cb-admin-promo-codes-form-row">
			<mat-form-field class="cb-admin-promo-codes-form-field" appearance="outline">
				<mat-label>montant</mat-label>
				<input
					matInput
					#promotion
					[min]="0"
					name="promotion"
					type="number"
					placeholder="montant (€)"
					formControlName="promotion"
					required
				/>
				<mat-icon matSuffix>euro</mat-icon>

				@if(this.promotionForm.controls['promotion'].hasError('required')) {
					<mat-error>montant requis</mat-error>
				}
				@if(this.promotionForm.controls['promotion'].hasError('pattern')) {
					<mat-error>doit être un nombre</mat-error>
				}
				@if(this.promotionForm.controls['promotion'].hasError('min')) {
					<mat-error>doit être supérieur à 0</mat-error>
				}
			</mat-form-field>
		</div>

		<div class="cb-admin-promo-codes-form-row">
			<mat-form-field class="cb-admin-promo-codes-form-field" appearance="outline">
				<mat-label>date d'expiration</mat-label>
				<input
					matInput
					#expiration
					name="expiration"
					type="text"
					placeholder="DD/MM/YYYY"
					formControlName="expiration"
					[matDatepicker]="picker"
					required
				>
				<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
				<mat-error>date d'expiration requise</mat-error>
			</mat-form-field>
		</div>

		<div class="cb-admin-promo-codes-form-row">
			<mat-form-field class="cb-admin-promo-codes-form-field" appearance="outline">
				<mat-label>nb. d'usages</mat-label>
				<input
					matInput
					#usages
					[min]="0"
					name="usages"
					type="number"
					placeholder="usages"
					formControlName="usages"
					required
				/>

				@if(this.promotionForm.controls['usages'].hasError('required')) {
					<mat-error>nb. d'usages requis</mat-error>
				}
				@if(this.promotionForm.controls['usages'].hasError('pattern')) {
					<mat-error>doit être un nombre</mat-error>
				}
				@if(this.promotionForm.controls['usages'].hasError('min')) {
					<mat-error>doit être supérieur à 0</mat-error>
				}
			</mat-form-field>
		</div>
	</form>

	<mat-dialog-actions class="cb-admin-promo-codes-buttons" align="end">
		<button
			mat-flat-button
			color="accent"
			type="submit"
			[disabled]="this.isLoading || this.promotionForm.invalid"
			(click)="this.submit()"
		>
			@if (this.isEditMode) { Modifier } @else { Ajouter }
		</button>

		<button
			mat-flat-button
			mat-dialog-close
			[disabled]="this.isLoading"
		>
			Annuler
		</button>
	</mat-dialog-actions>

</mat-dialog-content>