import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toDate',
	standalone: true
})
export class ToDatePipe implements PipeTransform {

	public transform = (value: Date | string): Date => new Date(value);

}
