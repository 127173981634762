import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";

import { ADMIN_LINKS } from "./admin-links";
import { Link } from "../_commons/models/link";

import { AdminMenuComponent } from "./admin-menu/admin-menu.component";
import { AdminPathComponent } from "./admin-path/admin-path.component";

@Component({
	selector: "app-admin",
	standalone: true,
	imports: [
		RouterOutlet,
		AdminMenuComponent,
		AdminPathComponent
	],
	templateUrl: "./admin.component.html",
	styleUrl: "./admin.component.scss",
})
export class AdminComponent {

	public links: Link[] = ADMIN_LINKS;

}
