<div class="cb-product">
	@if(this.product) {
		<div class="cb-product-container">

			<div class="cb-product-pictures">
				@if(this.previews.length) {
					<div class="cb-product-previews">
						@for(preview of previews; track $index) {
							<div
								class="cb-product-previews-preview {{ this.currentPreview === this.preview ? 'active' : '' }}"
								[style.background-image]="'url({0})' | replace : '{0}' : preview"
								(click)=" this.currentPreview = preview "
							></div>
						}
					</div>
				}

				<div
					class="cb-product-main-picture"
					[style.background-image]="'url({0})' | replace : '{0}' : this.currentPreview"
					[style.background-size]="this.product.isBox ? 'cover' : ''"
				></div>
			</div>
			
			<div class="cb-product-content">

				<div class="cb-product-heading">
					<h1>{{ this.product.name }}</h1>
					
					@if (this.product.prices.length) {
						<span class="cb-product-price">
							{{ (this.priceSelected.value * this.amount) | toPrice }}
							{{ this.priceSelected.unit === "EUR" ? "€" : this.priceSelected.unit }}
						</span>
					}
				</div>

				<div class="cb-product-paragraphe">{{ this.product.description }}</div>

				<div class="cb-product-cart-options">
					@if (this.product.prices.length) {
						<app-cb-quantity-selector
							class="cb-product-cart-quantity-selector"
							[amount]="this.amount"
							[prices]="this.product.prices"
							[priceSelected]="this.priceSelected"
							[isBox]="this.product.isBox"
							(amountEvent)=" this.amount = $event "
							(priceSelectedEvent)=" this.priceSelected = $event "
						></app-cb-quantity-selector>

						<app-cb-button
							class="cb-product-cart-button"
							text="Ajouter au panier"
							color="primary"
							(click)="this.addToCart()"
						></app-cb-button>
					}
				</div>
			</div>

		</div>
	}

	@else {
		<mat-progress-spinner
			class="cb-product-loading"
			diameter="48"
			mode="indeterminate"
		></mat-progress-spinner>
	}
</div>