<mat-dialog-content class="cb-address-form">
	@if (this.submitted) {
		<div class="cb-address-form-loading">
			<mat-progress-spinner
				diameter="48"
				mode="indeterminate"
			></mat-progress-spinner>
		</div>
	}

	<form [formGroup]="this.addressForm" (submit)="this.addressFormProcess()">
		<div class="cb-address-form-heading">
			@if (this.isEditMode) {
				<h3>modifier votre adresse</h3>
				<p>reseigner les nouveaux champs de votre adresse</p>
			}

			@else {
				<h3>ajouter votre adresse</h3>
				<p>reseigner les champs de votre nouvelle adresse</p>
			}
		</div>

		<div class="cb-address-form-row">
			<mat-form-field class="cb-address-form-field" appearance="outline">
				<mat-label>prénom</mat-label>
				<input
					matInput
					#firstName
					type="text"
					placeholder="prénom"
					formControlName="firstName"
					required
				/>
				<mat-error>prénom requis</mat-error>
			</mat-form-field>

			<mat-form-field class="cb-address-form-field" appearance="outline">
				<mat-label>nom</mat-label>
				<input
					matInput
					#lastName
					type="text"
					placeholder="nom"
					formControlName="lastName"
					required
				/>
				<mat-error>nom requis</mat-error>
			</mat-form-field>
		</div>

		<div class="cb-address-form-row">
			<mat-form-field class="cb-address-form-field" appearance="outline">
				<mat-label>rue</mat-label>
				<input
					matInput
					#street
					type="text"
					placeholder="rue"
					formControlName="street"
					required
				/>
				<mat-error>rue requise</mat-error>
			</mat-form-field>
		</div>

		<div class="cb-address-form-row">
			<mat-form-field class="cb-address-form-field" appearance="outline">
				<mat-label>code postal</mat-label>
				<input
					matInput
					#zip
					type="text"
					placeholder="code postal"
					formControlName="zip"
					required
				/>
				<mat-error>code postal requis</mat-error>
			</mat-form-field>

			<mat-form-field class="cb-address-form-field" appearance="outline">
				<mat-label>ville</mat-label>
				<input
					matInput
					#city
					type="text"
					placeholder="ville"
					formControlName="city"
					required
				/>
				<mat-error>ville requise</mat-error>
			</mat-form-field>
		</div>

		<div class="cb-address-form-row">
			<mat-form-field class="cb-address-form-field" appearance="outline">
				<mat-label>pays</mat-label>
				<mat-select
					matInput
					#country
					placeholder="pays"
					formControlName="country"
					required
				>
					@for (country of this.countries; track $index) {
						<mat-option [value]="country.value">{{ country.name }}</mat-option>
					}
				</mat-select>
				<mat-error>pays requis</mat-error>
			</mat-form-field>
	
			<mat-form-field class="cb-address-form-field" appearance="outline">
				<mat-label>téléphone</mat-label>
				<input
					matInput
					#phone
					type="text"
					placeholder="téléphone"
					formControlName="phone"
					required
				/>

				@if (this.addressForm.controls['phone'].hasError('required')) {
					<mat-error>téléphone requis</mat-error>
				}

				@if (this.addressForm.controls['phone'].hasError('pattern')) {
					<mat-error>téléphone incorrect</mat-error>
				}
			</mat-form-field>
		</div>

		<div class="cb-address-form-row">
			<div class="cb-address-form-field">
				<mat-checkbox
					class="cb-address-form-checkbox"
					formControlName="isBilledAddress"
				>adresse de facturation</mat-checkbox>

				<mat-checkbox
					class="cb-address-form-checkbox"
					formControlName="isDefaultBilledAddress"
					[disabled]="!this.addressForm.controls['isBilledAddress'].value"
					[matTooltip]="!this.addressForm.controls['isBilledAddress'].value ? 'Doit être une adresse de facturation !' : ''"
				>adresse de facturation par défaut</mat-checkbox>

				<mat-checkbox
					class="cb-address-form-checkbox"
					formControlName="isDefault"
				>adresse de livraison par défaut</mat-checkbox>
			</div>
		</div>

		<div class="cb-address-form-buttons">
			<button mat-flat-button color="accent" type="submit" [disabled]="this.submitted || this.addressForm.invalid">
				@if (this.isEditMode) { Modifier } @else { Ajouter }
			</button>

			<button mat-flat-button mat-dialog-close (click)="this.cancelEdit()">
				Annuler
			</button>
		</div>
	</form>
</mat-dialog-content>