import { BehaviorSubject, Observable } from 'rxjs';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

import { OrderedProduct } from '../../models/ordered-product';
import { Product } from '../../models/product';

@Injectable({
	providedIn: 'root'
})
export class CartService {

	private cartKey: string = 'cartItems';

	private approvalCart: BehaviorSubject<OrderedProduct[]> = new BehaviorSubject<OrderedProduct[]>(JSON.parse(this.getItem(this.cartKey)!) || []);
	public currentCart: Observable<OrderedProduct[]> = this.approvalCart.asObservable();

	constructor(
		private snackBar: MatSnackBar,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}

	private setItem(key: string, value: string): void {
		if(isPlatformBrowser(this.platformId))
			localStorage.setItem(key, value);
	}

	private getItem(key: string): any {
		if(isPlatformBrowser(this.platformId)) {
			const item = localStorage.getItem(key);
			return item || null;
		}

		return null;
	}

	private removeItem(key: string): void {
		if(isPlatformBrowser(this.platformId))
			localStorage.removeItem(key);
	}

	public updateCart(orderedProducts: OrderedProduct[]): void {
		this.approvalCart.next(orderedProducts);
		this.setItem(this.cartKey, JSON.stringify(orderedProducts));
	}

	public rebaseCart = (): void => this.approvalCart.next(JSON.parse(this.getItem(this.cartKey)!) || []);
	public resetCart = (): void => this.updateCart([]);
	public getCartItems = (): OrderedProduct[] => this.approvalCart.value || [];

	public addToCart(product: Product, quantity: number): void {
		const cartItems: OrderedProduct[] = this.getCartItems();

		const existingItem: OrderedProduct | undefined = cartItems.find(
			(item) =>
				item.product.id === product.id &&
				item.price.weight === product.prices[0].weight
		);

		if(existingItem)
			existingItem.quantity += ((existingItem.quantity + quantity) < 20) ? quantity : 0;

		else {
			const orderedProduct: OrderedProduct = {
				quantity: quantity,
				product: product,
				price: product.prices[0],
			};

			cartItems.push(orderedProduct);
		}

		this.updateCart(cartItems);

		this.snackBar.open(
			`${product.name} ajouté au panier`,
			'Fermer',
			{ duration: 2000 }
		);
	}

	public removeFromCart(orderedProduct: OrderedProduct): void {
		const cartItems: OrderedProduct[] = this.getCartItems();

		const updatedCartItems: OrderedProduct[] | undefined = cartItems.filter(
			(item) =>
				!(
					item.product.id === orderedProduct.product.id &&
					item.price.weight === orderedProduct.price.weight
				)
		);

		this.updateCart(updatedCartItems!);
	}

	public incrementCartItem(orderedProduct: OrderedProduct): void {
		const cartItems: OrderedProduct[] = this.getCartItems();

		const targetItem: OrderedProduct | undefined = cartItems.find(
			(item) =>
				item.product.id === orderedProduct.product.id &&
				item.price.weight === orderedProduct.price.weight
		);

		if(targetItem && targetItem.quantity < 20) {
			targetItem.quantity++;

			this.updateCart(cartItems);
		}
	}

	public decrementCartItem(orderedProduct: OrderedProduct): void {
		const cartItems: OrderedProduct[] = this.getCartItems();

		const targetItem: OrderedProduct | undefined = cartItems.find(
			(item) =>
				item.product.id === orderedProduct.product.id &&
				item.price.weight === orderedProduct.price.weight
		);

		if(targetItem && targetItem.quantity > 1) {
			targetItem.quantity--;

			this.updateCart(cartItems);
		}
	}

}
