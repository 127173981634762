import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { CbButtonComponent } from '../_commons/components/cb-button/cb-button.component';
import { SITEMAP, SiteMap } from '../_commons/sitemap';

@Component({
	selector: 'app-error',
	standalone: true,
	imports: [
		MatIconModule,
		CbButtonComponent
	],
	templateUrl: './error.component.html',
	styleUrl: './error.component.scss'
})
export class ErrorComponent {

	public sitemap: SiteMap = SITEMAP;

}
