import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from '../../models/user';

import { ApiUserService } from '../api/api-user/api-user.service';
import { AccountService } from '../account/account.service';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	private token: string = '';

	private approvalUsers: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
	public currentUsers: Observable<User[]> = this.approvalUsers.asObservable();

	constructor(
		private _user: ApiUserService,
		private _account: AccountService,
	) {
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');
		this.getUsers();
	}

	public async getUsers(): Promise<User[]> {
		const users: User[] = (await this._user.getAll(this.token))?.body || [];
		this.approvalUsers.next(users);

		return this.approvalUsers.getValue();
	}

	public async updateUser(user: User): Promise<User | null> {
		const users: User[] = await this.getUsers();

		users.map((o: User) => o.id === user.id ? user : o);
		this.approvalUsers.next(users);

		return null;
	}

}
