<mat-dialog-content class="cb-admin-generate-colissimo">

	@if (this.isLoading) {
		<div class="cb-admin-generate-colissimo-modal-loading">
			<mat-progress-spinner
				diameter="48"
				mode="indeterminate"
			></mat-progress-spinner>
		</div>
	}

	<div class="cb-admin-generate-colissimo-heading">
		<h2>Generate Colissimo</h2>
	</div>

	<form class="cb-admin-generate-colissimo-form" [formGroup]="this.labelForm" (submit)="this.submit()">

		<div class="cb-admin-products-form-row">
			<mat-form-field class="cb-admin-products-form-field" appearance="outline">
				<mat-label>poids (g)</mat-label>
				<input
					matInput
					#weight
					[min]="0"
					name="weight"
					type="number"
					placeholder="poids"
					formControlName="weight"
					required
				/>
	
				@if (this.labelForm.controls['weight'].hasError('required')) {
					<mat-error>poids requis</mat-error>
				}
				@if (this.labelForm.controls['weight'].hasError('pattern')) {
					<mat-error>poids doit être un nombre</mat-error>
				}
			</mat-form-field>
		</div>

		<div class="cb-admin-products-form-row-admin-products" [formGroup]="this.addressForm">

			<div class="cb-admin-products-form-row">
				<mat-form-field class="cb-admin-products-form-field" appearance="outline">
					<mat-label>prénom</mat-label>
					<input
						matInput
						#firstName
						type="text"
						placeholder="prénom"
						formControlName="firstName"
						required
					/>
					<mat-error>prénom requis</mat-error>
				</mat-form-field>
	
				<mat-form-field class="cb-admin-products-form-field" appearance="outline">
					<mat-label>nom</mat-label>
					<input
						matInput
						#lastName
						type="text"
						placeholder="nom"
						formControlName="lastName"
						required
					/>
					<mat-error>nom requis</mat-error>
				</mat-form-field>
			</div>
	
			<div class="cb-admin-products-form-row">
				<mat-form-field class="cb-admin-products-form-field" appearance="outline">
					<mat-label>rue</mat-label>
					<input
						matInput
						#street
						type="text"
						placeholder="rue"
						formControlName="street"
						required
					/>
					<mat-error>rue requise</mat-error>
				</mat-form-field>
			</div>
	
			<div class="cb-admin-products-form-row">
				<mat-form-field class="cb-admin-products-form-field" appearance="outline">
					<mat-label>code postal</mat-label>
					<input
						matInput
						#zip
						type="text"
						placeholder="code postal"
						formControlName="zip"
						required
					/>
					<mat-error>code postal requis</mat-error>
				</mat-form-field>
	
				<mat-form-field class="cb-admin-products-form-field" appearance="outline">
					<mat-label>ville</mat-label>
					<input
						matInput
						#city
						type="text"
						placeholder="ville"
						formControlName="city"
						required
					/>
					<mat-error>ville requise</mat-error>
				</mat-form-field>
			</div>
	
			<div class="cb-admin-products-form-row">
				<mat-form-field class="cb-admin-products-form-field" appearance="outline">
					<mat-label>pays</mat-label>
					<mat-select
						matInput
						#country
						placeholder="pays"
						formControlName="country"
						required
					>
						@for (country of this.countries; track $index) {
							<mat-option [value]="country.value">{{ country.name }}</mat-option>
						}
					</mat-select>
					<mat-error>pays requis</mat-error>
				</mat-form-field>
		
				<mat-form-field class="cb-admin-products-form-field" appearance="outline">
					<mat-label>téléphone</mat-label>
					<input
						matInput
						#phone
						type="text"
						placeholder="téléphone"
						formControlName="phone"
						required
					/>
	
					@if (this.addressForm.controls['phone'].hasError('required')) {
						<mat-error>téléphone requis</mat-error>
					}
	
					@if (this.addressForm.controls['phone'].hasError('pattern')) {
						<mat-error>téléphone incorrect</mat-error>
					}
				</mat-form-field>
			</div>

		</div>

	</form>

	<mat-dialog-actions class="cb-admin-generate-colissimo-form-buttons" align="end">
		<button
			mat-flat-button
			color="accent"
			type="submit"
			[disabled]="this.isLoading || this.isInvalidForms()"
			(click)="this.submit()"
		>
			Générer
		</button>

		<button
			mat-flat-button
			mat-dialog-close
			[disabled]="this.isLoading"
		>
			Annuler
		</button>
	</mat-dialog-actions>

</mat-dialog-content>