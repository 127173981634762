@if(this.orderedProduct) {
<div class="cb-order-item-product">
	@if(itemInfo) {
	<div class="cb-order-item-product-info">
		<div class="cb-order-item-product-title-ctnr">
			<a
				[routerLink]="[ this.sitemap.shopProduct.route, orderedProduct.product.id ]"
				class="cb-order-item-product-title"
			>
				{{ orderedProduct.product.name }}
			</a>

			@if(orderedProduct.amount && orderedProduct.price) {

			<span class="cb-order-item-product-price">
				{{ orderedProduct.amount }}
				{{ orderedProduct.price.unit === "EUR" ? "€" : "" }}
				{{ this.orderedProduct.price.weight }} g
			</span>
			}
		</div>
	</div>
	}
</div>
}
