import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { OrderStatus } from '../../models/order-status';

import { ApiOrderStatusService } from '../api/api-order-status/api-order-status.service';

@Injectable({
	providedIn: 'root'
})
export class OrderStatusService {

	private approvalOrderStatuses: BehaviorSubject<OrderStatus[]> = new BehaviorSubject<OrderStatus[]>([]);
	public currentOrderStatuses: Observable<OrderStatus[]> = this.approvalOrderStatuses.asObservable();

	constructor(
		private _orderStatus: ApiOrderStatusService
	) {
		this.getOrderStatuses();
	}

	public async getOrderStatuses(): Promise<OrderStatus[]> {
		if(!this.approvalOrderStatuses.getValue().length) {
			const orderStatuses: OrderStatus[] = (await this._orderStatus.getAll())?.body || [];
			this.approvalOrderStatuses.next(orderStatuses);
		}

		return this.approvalOrderStatuses.getValue();
	}

}
