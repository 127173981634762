import { Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { Product } from "../../models/product";

import { SITEMAP, SiteMap } from "../../sitemap";

import { ReplacePipe } from "../../pipes/replace/replace.pipe";
import { CartService } from "../../services/cart/cart.service";

import { ToPricePipe } from "../../pipes/to-price/to-price.pipe";
import { CbButtonComponent } from "../cb-button/cb-button.component";

@Component({
	selector: "app-cb-product-item",
	standalone: true,
	imports: [
		RouterModule,
		ReplacePipe,
		ToPricePipe,
		CbButtonComponent
	],
	templateUrl: "./cb-product-item.component.html",
	styleUrl: "./cb-product-item.component.scss",
})
export class CbProductItemComponent {

	@Input() public product!: Product;
	@Input() public title!: string;
	@Input() public solde!: boolean;

	public sitemap: SiteMap = SITEMAP;

	constructor(private _cart: CartService) {}

	public addToCart(): void {
		const selectedProduct: Product = {
			...this.product,
			prices: [ this.product.prices[0] ]
		};

		this._cart.addToCart(selectedProduct, 1);
	}

}
