<div class="cb-products">
	<h2 class="cb-products-title">{{ this.category?.name! || 'boutique' }}</h2>

	<div class="cb-products-list">

		@for (product of this.products; track $index) {
			@if(product.isAvailable) {
				<app-cb-product-item
					class="cb-products-product"
					[product]="this.product"
				></app-cb-product-item>
			}
		}

		@empty {
			@if(this.isLoading) {
				<mat-progress-spinner
					class="cb-products-loading"
					diameter="48"
					mode="indeterminate"
				></mat-progress-spinner>
			}

			@else { Aucun article dans la catégorie {{ this.category?.name }} }
		}
	</div>
</div>
