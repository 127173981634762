import { Country } from "./models/country";

export const COUNTRIES: Country[] = [
	{ name: "France", value: "FR" },
	// { name: "England", value: "EN" },
	// { name: "Deutschland", value: "DE" },
	// { name: "España", value: "ES" },
	// { name: "Italia", value: "IT" },
	// { name: "Portugal", value: "PT" },
	// { name: "Sverige", value: "SV" },
	// { name: "Suomi", value: "FI" },
	// { name: "Danmark", value: "DA" },
	// { name: "Polska", value: "PL" }
];