import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';

import { Address } from '../../models/address';
import { Promotion } from '../../models/promotion';

@Injectable({
	providedIn: 'root'
})
export class CheckoutService {

	private checkoutDataKey: string = 'checkout-data'

	private approvalDeliveredAddress: BehaviorSubject<Address | null> = new BehaviorSubject(JSON.parse(this.getItem(this.checkoutDataKey)!)?.deliveredAddress);
	private approvalBilledAddress: BehaviorSubject<Address | null> = new BehaviorSubject(JSON.parse(this.getItem(this.checkoutDataKey)!)?.billedAddress);
	private approvalPromotion: BehaviorSubject<Promotion | null> = new BehaviorSubject(JSON.parse(this.getItem(this.checkoutDataKey)!)?.promotion);

	public currentDeliveredAddress: Observable<Address | null> = this.approvalDeliveredAddress.asObservable();
	public currentBilledAddress: Observable<Address | null> = this.approvalBilledAddress.asObservable();
	public currentPromotion: Observable<Promotion | null> = this.approvalPromotion.asObservable();

	constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

	private setItem(key: string, value: string): void {
		if(isPlatformBrowser(this.platformId))
			localStorage.setItem(key, value);
	}

	private getItem(key: string): any {
		if(isPlatformBrowser(this.platformId)) {
			const item: string | null = localStorage.getItem(key);
			return item;
		}

		return null;
	}

	private removeItem(key: string): void {
		if(isPlatformBrowser(this.platformId))
			localStorage.removeItem(key);
	}

	private updateLocalStorage(): void {
		let data: any = {
			deliveredAddress: this.getDeliveredAddress(),
			billedAddress: this.getBilledAddress(),
			promotion: this.getPromotion()
		};

		this.setItem(this.checkoutDataKey, JSON.stringify(data));
	}

	public resetCheckout(): void {
		this.approvalDeliveredAddress.next(null)
		this.approvalBilledAddress.next(null)
		this.approvalPromotion.next(null)

		this.removeItem(this.checkoutDataKey);
	}

	public setDeliveredAddress(address: Address): void {
		this.approvalDeliveredAddress.next(address);
		this.updateLocalStorage();
	}

	public setBilledAddress(address: Address): void {
		this.approvalBilledAddress.next(address);
		this.updateLocalStorage();
	}

	public setPromotion(promotion: Promotion | null): void {
		this.approvalPromotion.next(promotion);
		this.updateLocalStorage();
	}

	public getDeliveredAddress = (): Address | null => this.approvalDeliveredAddress.getValue();
	public getBilledAddress = (): Address | null => this.approvalBilledAddress.getValue();
	public getPromotion = (): Promotion | null => this.approvalPromotion.getValue();

}
