import { Component } from '@angular/core';

@Component({
  selector: 'app-activate-card',
  standalone: true,
  imports: [],
  templateUrl: './activate-card.component.html',
  styleUrl: './activate-card.component.scss'
})
export class ActivateCardComponent {

}
