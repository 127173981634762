import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { Category } from "../../../_commons/models/category";

import { Product } from "../../../_commons/models/product";

import { ApiProductService } from "../../../_commons/services/api/api-product/api-product.service";
import { ApiCategoryService } from "../../../_commons/services/api/api-category/api-category.service";

import { CbProductItemComponent } from "../../../_commons/components/cb-product-item/cb-product-item.component";

@Component({
	selector: "app-products",
	standalone: true,
	imports: [
		MatProgressSpinnerModule,
		CbProductItemComponent
	],
	templateUrl: "./products.component.html",
	styleUrl: "./products.component.scss",
})
export class ProductsComponent implements OnInit {

	public categories: Category[] = []
	public products: Product[] = [];
	public category?: Category;

	public isLoading: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private _category: ApiCategoryService,
		private _product: ApiProductService
	) {}

	public async ngOnInit(): Promise<void> {
		this.isLoading = true;

		this.categories = (await this._category.getAll())?.body!;

		this.route.paramMap.subscribe(async (paramMap: ParamMap): Promise<void> => {
			this.isLoading = true;

			if(paramMap.get('category')) {
				this.category = this.categories.find((category: Category) => category.slug === paramMap.get('category')!)!;
				this.products = this.category.products;
			}

			else
				this.products = (await this._product.getAll())?.body!;

			this.isLoading = false;
		});

		this.isLoading = false;
	}
}
