import { Component, Inject, PLATFORM_ID, ViewChild } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";

import { SignInDto } from "../../_commons/dtos/sign-in.dto";

import { AccountService } from "../../_commons/services/account/account.service";

import { CbButtonComponent } from "../../_commons/components/cb-button/cb-button.component";
import { MatDialogModule, MatDialog } from "@angular/material/dialog";
import { MatMenuModule, MatMenuTrigger } from "@angular/material/menu";
import { CbModalComponent } from "../../_commons/components/cb-modal/cb-modal.component";
import { isPlatformBrowser } from "@angular/common";
import { SITEMAP, SiteMap } from "../../_commons/sitemap";

@Component({
	selector: "app-sign-in",
	standalone: true,
	imports: [
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatDialogModule,
		MatMenuModule,
		CbButtonComponent
	],
	templateUrl: "./sign-in.component.html",
	styleUrl: "./sign-in.component.scss",
})
export class SignInComponent {

	@ViewChild("menuTrigger") menuTrigger!: MatMenuTrigger;

	public sitemap: SiteMap = SITEMAP;
	public submitted: boolean = false;

	public signInForm: FormGroup = this.fb.group({
		email: ["", [Validators.required, Validators.email]],
		password: ["", [Validators.required, Validators.minLength(8)]],
	});

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private _account: AccountService,
		public dialog: MatDialog,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		// https://stackoverflow.com/questions/77534244/local-storage-is-not-defined-in-angular-17
		if (isPlatformBrowser(this.platformId))
			if (!localStorage.getItem("visited")) {
				this.dialog.open(CbModalComponent, {
					data: {
						title: 'Nous avons amélioré notre plateforme',
						message: 'Cher client, nous avons récemment migré notre site pour vous offrir une meilleure expérience. Cependant, en raison de cette migration, nous avons dû réinitialiser notre base de données clients. Nous vous invitons à recréer votre compte pour continuer à profiter de nos services. Nous nous excusons pour ce désagrément et apprécions votre compréhension.',
						buttonText: 'je recrée mon compte !'
					}
				});

				localStorage.setItem("visited", "true");
			}
	}

	public async signIn(): Promise<void> {
		if (this.signInForm.invalid)
			return;

		this.submitted = true;

		let signInDto: SignInDto = { ...this.signInForm.value };
		let success: boolean = await this._account.login(signInDto);

		if (success)
			this.router.navigate([SITEMAP.account.route]);

		else
			this.dialog.open(CbModalComponent, {
				data: {
					title: 'Échec de la connexion',
					message: 'Les informations de connexion utilisateur que vous venez de renseigner semblent incorrectes',
					buttonText: 'fermer'
				}
			});

		this.submitted = false;
	}

}
