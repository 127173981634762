import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { API_URL } from "../../../constants";
import { HEADERS } from "../headers";
import { OrderStatus } from "../../../models/order-status";

@Injectable({
	providedIn: 'root'
})
export class ApiOrderStatusService {

	private API_URL: string = API_URL;
	private headers: HttpHeaders = HEADERS;
	private ORDER_STATUS_PATH: string = "order-status";

	constructor(private http: HttpClient) {}

	public async getAll(): Promise<HttpResponse<OrderStatus[]> | undefined> {
		return this.http
			.get<OrderStatus[]>(`${this.API_URL}/${this.ORDER_STATUS_PATH}/`, {
				headers: this.headers,
				observe: 'response'
			})
			.toPromise();
	}

	public async getById(id: number): Promise<HttpResponse<OrderStatus> | undefined> {
		return this.http
			.get<OrderStatus>(`${this.API_URL}/${this.ORDER_STATUS_PATH}/${id}`, {
				headers: this.headers,
				observe: 'response'
			})
			.toPromise();
	}

}
