import { Pipe, PipeTransform } from '@angular/core';

import { Address } from '../../models/address';

@Pipe({
	name: 'toAddress',
	standalone: true
})
export class ToAddressPipe implements PipeTransform {

	public transform = (value: Address | string): Address => {
		if (typeof value === 'string' && value.trim()) {
			try {
				return JSON.parse(value);
			} catch (e) {
				console.error('Error parsing JSON in ToAddressPipe:', e);
				return {} as Address;
			}
		}
		return value as Address;
	}
	
}
