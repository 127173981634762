import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ADMIN_LINKS } from '../admin-links';
import { Link } from '../../_commons/models/link';
import { Order } from '../../_commons/models/order';

import { OrderService } from '../../_commons/services/order/order.service';

@Component({
	selector: 'app-admin-index',
	standalone: true,
	imports: [
		RouterModule,
		MatBadgeModule,
		MatButtonModule,
		MatIconModule
	],
	templateUrl: './admin-index.component.html',
	styleUrl: './admin-index.component.scss'
})
export class AdminIndexComponent {

	public links: Link[] = ADMIN_LINKS;
	public orders: Order[] = [];
	public orderRemains: Order[] = [];

	constructor(
		private _order: OrderService,
	) {
		this._order.currentOrders.subscribe((orders: Order[]) => {
			this.orders = orders;
			this.orderRemains = this.orders.filter((order: Order) => [3,4,5].includes(order.status.id));
		});
	}

}
