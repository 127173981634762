<div class="cb-faq">
	<div class="cb-faq-container">
		<h2>FAQ</h2>

		<mat-accordion class="cb-faq-questions" [multi]="true">

			@for (faqSection of this.faqSections; track $index) {
				<h3>{{ faqSection.title }}</h3>

				@for (question of faqSection.questions; track $index) {
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>{{ question.question }}</mat-panel-title>
						</mat-expansion-panel-header>
		
						<p>{{ question.answer }}</p>
					</mat-expansion-panel>
				}
			}

		</mat-accordion>
	</div>
</div>
