import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "replace",
	standalone: true,
	pure: false,
})
export class ReplacePipe implements PipeTransform {

	public transform = (string: string, target: string, value: string | number) =>
		string.replace(target, value.toString());

}
