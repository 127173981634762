import { Component } from '@angular/core';

@Component({
  selector: 'app-conditions',
  standalone: true,
  imports: [],
  templateUrl: './conditions.component.html',
  styleUrl: './conditions.component.scss'
})
export class ConditionsComponent {

}
