@if(this.order) {
<div class="cb-order-item">
	<div class="cb-order-item-header">
		<div class="cb-order-item-row">
			<div class="cb-order-item-column">
				{{ this.order.status.status }}
			</div>
		</div>

		<div class="cb-order-item-row">
			<div class="cb-order-item-column">
				@if (this.order.deliveredAddress | toAddress; as deliveredAddress) {
					{{ deliveredAddress.street }}, {{ deliveredAddress.zip }},
					{{ deliveredAddress.city }}
				}
			</div>
		</div>

		<div class="cb-order-item-row">
			<div class="cb-order-item-column"># {{ this.order.id }}</div>
			<div class="cb-order-item-column">
				{{ this.order.date.toLocaleDateString() }}
			</div>
			<div class="cb-order-item-column">{{ "CB" }}</div>
			<div class="cb-order-item-column">{{ this.order.amount }} €</div>
		</div>
	</div>

	<div class="cb-order-sumry-ctnr">
		<mat-accordion>
			<mat-expansion-panel class="cb-expanse-pan" hideToggle="true" #panel>
				<!--@if(displayProductItemInfo) {-->
				<mat-expansion-panel-header
					[collapsedHeight]="customCollapsedHeight"
					[expandedHeight]="customExpandedHeight"
				>
					<div class="cb-order-header-ctnr">
						<div class="cb-order-header-algn">
							@for(orderedProduct of this.order.cart.orderedProducts | slice : 0 : 2
							; track orderedProduct) {
								<div class="cb-mobile">
									<app-order-item-product
										[itemInfo]="displayProductItemInfo"
										[orderedProduct]="orderedProduct"
									>
									</app-order-item-product>
								</div>
							}

							@for(orderedProduct of this.order.cart.orderedProducts | slice : 0 :
							4; track orderedProduct) {
								<div class="cb-desktop">
									<app-order-item-product
										[itemInfo]="displayProductItemInfo"
										[orderedProduct]="orderedProduct"
									>
									</app-order-item-product>
								</div>
							}
						</div>

						<div class="cb-btn-ctnr">
							@if(!displayProductItemInfo) {
								<button mat-icon-button class="cb-btn-icn">
									<mat-icon>expand_more</mat-icon>
								</button>
							}

							<div class="cb-btn-algn">
								@if(!displayProductItemInfo && this.order.cart.orderedProducts.length) {
									<button mat-flat-button class="cb-btn-flat cb-no-btn-icn">
										Informations de commande
									</button>
								}
							</div>
						</div>
					</div>
				</mat-expansion-panel-header>
				<!--}-->

				<div class="cb-order-opened-ctnr">
					<app-cb-order-status-progress
						class="cb-orders-order-details-progress"
						[status]="this.order.status"
						[statusLength]="this.isShopAddress(this.order.deliveredAddress) ? 6 : 7"
					></app-cb-order-status-progress>

					@for(orderedProduct of this.order.cart.orderedProducts | slice : 2 :
					order.cart.orderedProducts.length ; track orderedProduct ) {

					<div class="cb-product">
						<app-order-item-product
							[orderedProduct]="orderedProduct"
							[itemInfo]="displayProductItemInfo"
						>
						</app-order-item-product>
					</div>
					}
				</div>
				@if(displayProductItemInfo){

				<div class="cb-btn-ctnr">
					<div class="cb-btn-algn">
						<button mat-flat-button class="cb-btn-flat">
							Informations de commande
						</button>
					</div>

					<button mat-icon-button (click)="panel.close()" class="cb-btn-icn">
						<mat-icon>expand_less</mat-icon>
					</button>
				</div>
				}
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
}
