import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { SITEMAP } from '../../sitemap';
import { User } from '../../models/user';

import { AccountService } from '../../services/account/account.service';

export const isAdminGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

	const router: Router = inject(Router);
	const _account: AccountService = inject(AccountService);

	const user: User | null = _account.getUser();

	if(user && user.role.isAdmin)
		return true;

	router.navigate([ SITEMAP.main.route ]);
	return false;

};
