import { Component } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { RouterModule } from "@angular/router";

import { SITEMAP, SiteMap } from "../../_commons/sitemap";
import { Category } from "../../_commons/models/category";

import { ApiCategoryService } from "../../_commons/services/api/api-category/api-category.service";

@Component({
	selector: "app-footer",
	standalone: true,
	imports: [
		RouterModule,
		MatExpansionModule
	],
	templateUrl: "./footer.component.html",
	styleUrl: "./footer.component.scss",
})
export class FooterComponent {

	public sitemap: SiteMap = SITEMAP;
	public categories: Category[] = [];

	constructor(private _category: ApiCategoryService) {}

	public async ngOnInit(): Promise<void> {
		this.categories = (await this._category.getAll())?.body!;
	}

}
