import { Component, Input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { Role } from '../../../_commons/models/role';
import { User } from '../../../_commons/models/user';
import { RoleService } from '../../../_commons/services/role/role.service';
import { UserService } from '../../../_commons/services/user/user.service';

@Component({
	selector: 'app-admin-users-item',
	standalone: true,
	imports: [
		MatFormFieldModule,
		MatSelectModule,
	],
	templateUrl: './admin-users-item.component.html',
	styleUrl: './admin-users-item.component.scss'
})
export class AdminUsersItemComponent {

	@Input() public user!: User;

	public roles: Role[] = [];

	public isLoading: boolean = false;

	constructor(
		private _role: RoleService,
		private _user: UserService
	) {
		this._role.currentRoles.subscribe((roles: Role[]) => this.roles = roles);
	}

	public async updateUserRole(roleId: number): Promise<void> {
		this.user.role = this.roles.find((r: Role) => r.id === roleId)!;
		this.isLoading = true;

		const updatedUser: User | null = await this._user.updateUser(this.user);

		if(updatedUser)
			this.user = updatedUser;

		this.isLoading = false;
	}

}
