<div class="cb-checkout-end">

	@if(isLoading) {
		<mat-progress-spinner diameter="48" mode="indeterminate"></mat-progress-spinner>
	}

	@else {
		<div class="cb-checkout-end-icon">
			@if (this.success) { <mat-icon class="cb-checkout-end-success">check</mat-icon> }
			@if (!this.success) { <mat-icon class="cb-checkout-end-failed">close</mat-icon> }
		</div>
	
		<h2 class="cb-checkout-end-message">{{ this.success ? 'commande réussie !' : 'échec de la commande !' }}</h2>
	
		<app-cb-button
			color="primary"
			[text]="this.success ? 'Retour à la boutique' : 'réessayer'"
			[routerLink]="[this.success ? this.sitemap.shop.route : this.sitemap.checkoutReady.route]"
		></app-cb-button>
	}

</div>