import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toPrice',
	standalone: true
})
export class ToPricePipe implements PipeTransform {

	public transform = (value: number): string => (value).toFixed(2);

}
