<div class="cb-conditions">
	<div class="cb-conditions-ctnr">
		<h2>Conditions générales</h2>
		<p>
			Les présentes Conditions Générales de Vente sont conclues par la société
			<strong>CARREMENT BON SARL Christine Vallot</strong>, ci-après désignée «
			CARREMENT BON » ou « le Vendeur » et, d'autre part, par toute personne
			physique agissant en qualité de consommateur et souhaitant procéder à un
			achat via le site Internet de CARREMENT BON dénommée ci-après le « Client
			» ou « l'Acheteur ».
		</p>
		<p>
			Le client désigne toute personne physique qui procède à un abonnement sur
			le Site.
		</p>
		<p>
			Les « produits » ou « articles » désignent les produits en vente sur le
			site, notamment les boîtes à biscuits et la souscription d'abonnement.
		</p>
		<p>
			La boutique en ligne est accessible à l'adresse suivante :
			<a href="https://www.carrementbon.fr" target="_blank">www.carrementbon.fr</a>,
			dénommée ci-après « le site ».
		</p>

		<h3>Définitions</h3>
		<ul>
			<li>
				<strong>Visiteur :</strong> toute personne se rendant sur le site
				www.carrementbon.fr
			</li>
			<li>
				<strong>Client :</strong> toute personne ayant souscrit pour lui ou pour
				offrir à une ou plusieurs offres proposées par la société CARREMENT BON,
				via le site internet www.carrementbon.fr
			</li>
			<li>
				<strong>Produit :</strong> tout objet sélectionné par la société
				CARREMENT BON, et présent dans les boîtes à biscuits distribuées par
				ladite société
			</li>
			<li>
				<strong>Site :</strong> le site internet www.carrementbon.fr et ses
				sous-domaines
			</li>
		</ul>

		<ol>

			<li>
				<h3>Coordonnées de la société</h3>
				<p>
					Le site internet
					<a href="https://www.carrementbon.fr" target="_blank">www.carrementbon.fr</a>
					est édité par la société SARL Christine Vallot, au capital social de
					1000€, immatriculée au RCS de Toulouse, sous le numéro SIREN 848898862,
					numéro de TVA FR10 848898862, ayant son siège social au 3, chemin des
					étoiles - 31330 LAUNAC ; ci-après désignée "CARREMENT BON" et/ou "SARL
					Christine Vallot" dans les présentes conditions générales.
				</p>
			</li>

			<li>
				<h3>Conditions générales de vente</h3>
				<p>
					Les présentes Conditions Générales de Vente ont pour objet de fixer les
					dispositions contractuelles entre la société CARREMENT BON et le Client
					agissant en qualité de consommateur dans le cadre de l'achat de boîtes à
					biscuits, d'abonnements ou de produits par le biais du site marchand de la
					société. L'achat de boîtes à biscuits, de produits, la souscription
					d'abonnement à travers le présent site implique une acceptation sans
					réserve par le client des présentes Conditions Générales de Vente.
				</p>
				<p>
					Les services et produits vendus par l'intermédiaire du site sont réservés
					à l'usage strictement personnel du consommateur. Les produits ne peuvent
					en aucun cas être revendus par le client ou exploités à des fins
					commerciales.
				</p>
				<p>
					Les Conditions Générales de Vente sont accessibles à tout moment sur le
					site internet, et tout visiteur, avant même d'engager un processus de
					commande, peut les consulter, les imprimer ou les télécharger. Le
					processus de commande inclut les Conditions Générales de Vente et un
					processus de validation de la consultation de ces dernières et leur
					acceptation par le client.
				</p>
				<p>
					Les présentes Conditions Générales de Vente s'appliquent à l'exclusion de
					toutes autres conditions, et notamment celles applicables pour les autres
					circuits de distribution et de commercialisation.
				</p>
				<p>
					Ces Conditions Générales de Vente pouvant faire l'objet de modifications
					ultérieures, la version applicable à l'achat du Client est celle en
					vigueur sur le site internet à la date de passation de la commande.
				</p>
				<p>
					L'utilisation et l'accès au site
					<a href="https://www.carrementbon.fr" target="_blank">www.carrementbon.fr</a>
					entraîne systématiquement l'adhésion du client aux présentes conditions
					générales de vente. En cochant la case « j'ai lu et j'accepte les
					conditions générales de vente » au moment de la confirmation de la
					souscription à un abonnement, le client accepte pleinement et entièrement
					les présentes Conditions Générales de Vente.
				</p>
				<p>
					Sauf preuve contraire, les données enregistrées dans le système
					informatique de la société CARREMENT BON constituent la preuve de
					l'ensemble des transactions conclues avec le Client.
				</p>
			</li>

			<li>
				<h3>Conditions d'abonnement</h3>
				<p>
					Le Client a la possibilité d'acheter des boites à biscuits, des produits
					au détail, en procédant à des commandes ponctuelles sur le Site, ou de
					s'abonner au service d'envoi qui consiste en l'expédition chaque mois
					d'une boite à biscuits comprenant la recharge de sa boite composée d'au
					moins trois recettes de biscuits différentes .
				</p>
				<p>
					La souscription d'un abonnement au service d'envoi s'effectue sur le site.
					Tous les abonnements souscrits avant le 7 de chaque mois permettent de
					bénéficier de la boîte à biscuits du mois dans la limite des stocks
					disponibles. En cas de souscription d'un abonnement après cette date, la
					première boîte à biscuits sera livrée au mois suivant.
				</p>
				<p>
					La société CARREMENT BON propose 2 catégories d'abonnements au service
					d'envoi de boîte à biscuits dont les modalités sont les suivantes:
				</p>

				<ul>
					<li>
						L'abonnement Mensuel avec paiement par prélèvement bancaire chaque mois
						: la souscription à l'abonnement Mensuel abonne le souscripteur au
						service d'envoi mensuel d'une boîte à biscuits jusqu'à ce qu'une partie
						décide de mettre fin au contrat. En choisissant cette offre, le client
						accepte un prélèvement mensuel d'un montant défini lors de la
						souscription de l'abonnement Mensuel.
					</li>
					<li>
						L'abonnement Trimestriel, avec un paiement unique : la souscription à
						l'abonnement Trimestriel permet de s'abonner ou d'abonner un tiers au
						service d'envoi de boîte à biscuits pour une période de 3 mois, soit 3
						boîtes à biscuits. Le paiement de l'Abonnement Trimestriel s'effectue en
						une seule fois par carte bancaire au moment de la souscription de
						l'Abonnement Trimestriel par le Client.
					</li>
				</ul>

				<p>
					Le Client pourra souscrire à une des offres proposées par la société
					CARREMENT BON. Le tarif applicable sera celui en vigueur au jour de la
					commande.
				</p>
			</li>

			<li>
				<h3>Caractéristiques des produits proposés à la vente</h3>
				<p>
					En dehors de ces différents biscuits, la boîte à biscuits pourra contenir
					des offres promotionnelles (de CARREMENT BON ou de ses partenaires), des
					informations sur les produits, des fiches de recette ou tout autre
					document ou produits relatifs aux activités de CARREMENT BON.
				</p>
				<p>
					Concernant le service d'abonnement, il est expressément convenu entre les
					parties que le client s'abonne au service d'envoi de boîte à biscuits «
					surprise », c'est-à-dire que le client ne connaît pas à l'avance les
					biscuits contenus par les boîtes livrées durant la période de
					l'abonnement. Le client a toute connaissance et accepte pleinement les
					caractéristiques de ce service.
				</p>
				<p>
					Pour tout achat de produits hors abonnement, les offres sont valables dans
					la limite des stocks disponibles, tels que précisés lors de la passation
					de commande.
				</p>
				<p>
					Les caractéristiques principales des boîtes à biscuits et produits sont
					présentées sur le site internet. Le client est tenu d'en prendre
					connaissance avant toute passation de commande. Le choix et l'achat d'une
					boîte à biscuits sont de la seule responsabilité du Client. Les
					photographies et graphismes présentés sur le site internet ne sont pas
					contractuels et ne sauraient engager la responsabilité de la société
					CARREMENT BON. Le Client est tenu de se reporter au descriptif de chaque
					boîte à biscuits afin d'en connaître les propriétés et les particularités
					essentielles.
				</p>
				<p>
					Conformément à l'article L. 111-1 du Code de la Consommation, le Client
					peut préalablement à sa commande prendre connaissance sur le site des
					caractéristiques essentielles des services et produits qu'il désire
					commander.
				</p>
				<p>
					Nos offres sont exclusivement réservées à la clientèle particulière, et en
					aucun cas aux revendeurs professionnels.
				</p>
			</li>

			<li>
				<h3>Disponibilité des produits</h3>
				<p>
					Les offres proposées par la société CARREMENT BON sont dites « disponibles
					» tant qu'elles sont présentées sur le site, c'est-à-dire, dans la limite
					des stocks disponibles.
				</p>
				<p>
					En cas d'indisponibilité d'un ou plusieurs produits ou services après
					passation de la commande, le Client sera averti par mail. Le montant de sa
					commande sera recalculé et le Client sera remboursé de la différence. Si
					la Commande est entièrement indisponible le Client sera averti par mail et
					sera intégralement remboursé.
				</p>
				<p>
					Dans ce cas, CARREMENT BON ne pourra être tenu responsable d'aucun
					préjudice subi par Le Client, ni tenu à aucun dommages-intérêts.
				</p>
			</li>

			<li>
				<h3>Tarifs et modes de paiement</h3>
				<p>
					Les tarifs des offres proposées par la société CARREMENT BON sont précisés
					dans le formulaire d'abonnement, sauf en cas d'erreur manifeste. Ils sont
					consultables à tout moment sur le Site. L'ensemble des prix est en euros,
					soumis à la TVA. Ainsi toute modification du taux de TVA pourra être
					reportée sur les prix en vigueurs.
				</p>
				<p>
					Les frais de port ne sont pas inclus dans les tarifs, sauf précision
					contraire dans le formulaire d'abonnement (notamment à l'occasion d'offres
					promotionnelles). Ces frais d'expédition seront indiqués avant
					l'enregistrement de la commande par Le Client.
				</p>
				<p>
					Pour toutes les offres, à l'exception de celle dite « sans engagement »,
					le Client procède au paiement en une fois. Pour l'offre dite « sans
					engagement », l'abonnement est payé par prélèvement mensuel sur le compte
					du Client, chaque mois, aux alentours du 7 du mois. Toutes les factures
					sont disponibles dans la rubrique « mon compte » du site internet.
				</p>
				<p>
					Pour les offres dites « sans engagement », la société CARREMENT BON se
					réserve le droit de modifier unilatéralement ses tarifs. Le Client recevra
					alors, par courrier électronique à l'adresse indiquée lors de la
					souscription de l'abonnement, le nouveau tarif. Celui-ci rentrera en
					vigueur au cours du mois suivant celui de la réception de cette
					notification. Si le client souhaite se désabonner suite à cette
					modification du prix de l'abonnement, il devra suivre la procédure
					expliquée à l'article 7 des présentes conditions générales de vente.
				</p>
				<p>
					Tout paiement effectué sur le site www.carrementbon.fr est sécurisé et
					assuré par l'intermédiaire de Stripe.
				</p>
				<p>
					Le transfert de la propriété des produits commandés sur le Site ne sera
					effectif qu'à réception par la société CARREMENT BON du paiement complet
					de la commande par le Client.
				</p>
			</li>

			<li>
				<h3>Désabonnement</h3>
				<h4>Désabonnement dans le cadre d'un Abonnement Mensuel</h4>
				<p>
					Le Client peut à tout moment mettre un terme son Abonnement Mensuel sur
					son espace « Mon compte ». La résiliation de ce contrat d'Abonnement
					mensuel pourra être réalisée par le Client à tout moment.
				</p>
				<p>
					Tout Abonnement résilié avant le 25 de chaque mois met fin à l'Abonnement
					dès le mois suivant. À défaut de résiliation de l'Abonnement avant le 25
					du mois, le service d'envoi de Boîte à biscuits se poursuivra pour le mois
					suivant celui de la résiliation de l'Abonnement.
				</p>
				<p>
					Le Désabonnement peut également résulter de la décision de la société
					CARREMENT BON suivant les modalités prévues à l'article relatif à la
					Désactivation de Compte Client.
				</p>

				<h4>Désabonnement dans le cadre des Abonnements Trimestriel</h4>
				<p>
					Les Clients bénéficiant de l'abonnement trimestriel ne pourront se
					désabonner avant la fin de la période couverte par l'abonnement,
				</p>
			</li>

			<li>
				<h3>Désactivation du compte client</h3>
				<p>
					En cas de non-respect des obligations découlant de l'acceptation des
					présentes Conditions Générales de Vente, d'incidents de paiement du prix
					d'une commande, de délivrance d'informations erronées ou d'actes
					susceptibles de nuire aux intérêts de la société CARREMENT BON, la société
					se réserve le droit de suspendre l'accès aux services proposés sur le
					Site, ou en fonction de la gravité de ces actes, de résilier l'Abonnement
					et le Compte du client sans que des dommages et intérêts puissent être
					réclamés.
				</p>
				<p>
					CARREMENT BON se réserve également le droit de refuser de contracter avec
					un Client ayant été exclu ou sanctionné pour de tels agissements.
				</p>
			</li>

			<li>
				<h3>Commandes</h3>
				<p>
					Le processus de passation de commande est applicable lors de l'achat de
					boîte à biscuits, produits au détail ou lors de la souscription
					d'Abonnement. Afin de passer commande sur le Site, le Client doit
					obligatoirement accomplir les démarches suivantes :
				</p>
		
				<ul>
					<li>Choix des produits et/ou des Abonnements et ajout au panier ;</li>
					<li>
						Validation du contenu du panier et du récapitulatif de la commande ;
					</li>
					<li>
						Identification sur le site internet et inscription sur la fiche
						d'identification sur laquelle il indiquera toutes les coordonnées
						demandées ;
					</li>
					<li>Acceptation des Conditions Générales de Vente ;</li>
					<li>Validation du paiement.</li>
				</ul>
		
				<p>Le Client recevra un e-mail de confirmation de commande.</p>
				<p>
					Il appartient au Client de vérifier l'exactitude de la commande et de
					signaler immédiatement toute erreur.
				</p>
				<p>
					Les offres sont valables tant qu'elles sont visibles sur le site, dans la
					limite des stocks disponibles.
				</p>
				<p>
					Par ailleurs, en remplissant le formulaire de commande des renseignements
					seront demandés au Client. A cet effet, le Client s'engage à fournir des
					informations actuelles, complètes et exactes et maintenir et mettre à jour
					ces informations afin qu'elles soient toujours actuelles, complètes et
					exactes. Tous les champs obligatoires signalés comme tels dans le
					formulaire de renseignements devront être renseignés. Le Client est seul
					responsable du préjudice direct ou indirect qu'il est susceptible de subir
					en l'absence d'actualisation de ces informations, et en assume seul les
					conséquences.
				</p>
				<p>
					La vente ne sera considérée comme définitive qu'après l'envoi à l'Acheteur
					de la confirmation de l'acceptation de la commande par courrier
					électronique et après encaissement de l'intégralité du Prix.
				</p>
				<p>
					CARREMENT BON se réserve le droit d'annuler ou de refuser toute commande
					qui émanerait d'un Client avec lequel il existerait un litige relatif au
					paiement d'une commande précédente ou un Client qui n'aurait pas la
					capacité de contracter. Toute commande vaut acceptation des prix et des
					descriptions des articles disponibles à la vente.
				</p>
			</li>

			<li>
				<h3>Livraison</h3>
				<p>
					Nos boîtes à biscuits sont expédiées avant le 20 de chaque mois, à
					l'adresse indiquée par le Client lors de sa commande sur le site internet,
					sous réserve de validation du paiement. CARREMENT BON s'engage à faire ses
					meilleurs efforts pour livrer les Produits commandés par le Client dans
					les délais ci-dessus précisés. Toutefois, ces délais sont communiqués à
					titre indicatif.
				</p>
				<p>
					En cas de changement d'adresse de livraison, il appartient au Client de le
					notifier Avant le 5 du mois concerné par la livraison de la prochaine
					boîte à biscuits en modifiant ses coordonnées sur le formulaire de
					renseignement dans l'espace « Mon compte ».
				</p>
				<p>
					Dans le cas où la boîte à biscuits serait retournée à CARREMENT BON, une
					seconde livraison sera effectuée avec une nouvelle application des frais
					de port. Si la boîte à biscuits est de nouveau retournée à la société, il
					n'y aura pas de nouvelle expédition.
				</p>
				<p>
					Le mode de livraison est choisi par le Client au moment de la passation de
					la commande ou de la souscription de l'Abonnement.
				</p>
				<p>
					Les délais de livraison indiqués sur le Site sont des délais indicatifs,
					correspondant aux délais moyens de traitement et de livraison constatés.
				</p>
				<p>
					La modification du mode de livraison en cours d'Abonnement est possible
					uniquement pour l'Abonnement Mensuel depuis l'espace « Mon Compte » du
					Client. Si le changement de mode de livraison est réalisé avant le 5 du
					mois précédant la livraison de la prochaine boîte à biscuits, cette
					modification du mode de livraison sera prise en compte dès le mois
					concerné. Le montant du prélèvement automatique mensuel sera modifié afin
					de tenir compte de la variation du montant des frais de livraison.
				</p>
				<p>
					La livraison est constituée par le transfert au Client de la possession
					physique ou du contrôle du Produit.
				</p>
				<p>
					Les livraisons sont assurées par des transporteurs indépendants, à
					l'adresse mentionnée par le Client lors de la commande ou modifiée
					ultérieurement dans les conditions précisées précédemment et à laquelle le
					transporteur pourra facilement accéder.
				</p>
				<p>
					En cas d'erreur dans les informations transmises par le Client, CARREMENT
					BON ne saurait être tenu responsable de l'impossibilité de livrer les
					boites à biscuits en lieu et temps voulus.
				</p>
				<p>
					CARREMENT BON ne pourra voir sa responsabilité engagée pour les retards de
					livraison et les conséquences qui pourraient en découler.
				</p>
			</li>

			<li>
				<h3>TRANSFERT DE PROPRIETE - TRANSFERT DES RISQUES</h3>
				<p>
					Le transfert de propriété des Produits au profit du Client ne sera réalisé
					qu'après complet paiement du prix par ce dernier, et ce quelle que soit la
					date de livraison desdits Produits. Quelle que soit la date du transfert
					de propriété des Produits, le transfert des risques de perte et de
					détérioration s'y rapportant, ne sera réalisé qu'au moment où le Client
					prendra physiquement possession des Produits. Les Produits voyagent donc
					aux risques et périls du Vendeur dès lors que le Vendeur se charge de
					l'acheminement du bien vendu.
				</p>
			</li>

			<li>
				<h3>Droit de rétractation et remboursement</h3>
				<p>
					Conformément à l'article L121-201 du Code de la Consommation, le Client
					dispose d'un délai de quatorze jours francs pour exercer un droit de
					rétractation. Ce délai court à compter de la date de réception de la boite
					à biscuits. Le Client pourra se rétracter sans avoir de justification à
					donner et de pénalités à payer.
				</p>
				<p>
					Pour exercer ce droit, le Client doit demander à la société CARREMENT BON
					un formulaire de rétractation et retourner le colis à la société CARREMENT
					BON, à l'adresse : 3, chemin des étoiles - 31330 LAUNAC. Le colis devra
					comprendre l'emballage d'origine (comprenant l' emballage de chacun des
					produits) et les produits non ouverts, permettant une nouvelle
					commercialisation à l'état neuf.
				</p>
				<p>
					Le Client est donc tenu d'apporter un soin raisonnable aux produits
					laissés en sa possession. Aussi, ne seront repris ou échangés, les
					produits détériorés, incomplets ou présentant des traces d'utilisation.
				</p>
				<p>
					Si les conditions énoncées ci-dessus sont remplies, la Société CARREMENT
					BON remboursera au client le prix du coffret mensuel. En cas de
					rétractation, l'abonnement auquel aurait souscrit le Client sera
					automatiquement suspendu.
				</p>
				<p>
					Les frais de retour des produits seront à la charge du Client, et le
					remboursement s'effectuera dans un délai de 30 jours après réception du
					colis, dans les conditions rappelées ci-dessus par la société CARREMENT
					BON.
				</p>
				<p>
					Ce droit de rétractation est également applicable dans les mêmes
					conditions dans le cadre de la souscription d'Abonnements sur le Site.
					Ainsi, le Client a la possibilité de rétracter la souscription de son
					Abonnement dans un délai de 14 jours à compter de la validation de la
					commande portant sur cet Abonnement.
				</p>
			</li>

			<li>
				<h3>Information et confidentialité</h3>
				<p>
					Dans le cadre de ses activités, la société CARREMENT BON constitue un
					fichier informatique contenant des informations personnelles sur ses
					clients. Ce fichier est constitué dans le but de suivre les commandes,
					assurer la livraison, améliorer le service proposé aux clients ainsi que
					de leur proposer des offres commerciales.
				</p>
				<p>
					Conformément à la loi du 6 janvier 1978, modifiée par la loi n° 2004-801
					du 6 août 2004 (déclaration CNIL n°1897438), le client pourra à tout
					moment modifier, supprimer ou rectifier ses données personnelles. Pour
					cela, il pourra faire une modification via l'espace « Mon Compte », ou par
					courrier (à l'adresse mentionnée à l'article 1 des présentes CGV) en
					indiquant ses nom, prénom et adresse postale.
				</p>
				<p>
					De même, le client pourra revenir sur son choix de recevoir des offres
					promotionnelles à tout moment, que ce soit de la société CARREMENT BON ou
					de ses partenaires, soit en cliquant sur le lien prévu à cet effet dans
					les mails promotionnels reçus, soit en utilisant l'espace dédié à cela
					dans l'espace « Mon compte ».
				</p>
				<p>
					La société CARREMENT BON fournit à ses clients des identifiants de
					connexion, strictement personnels et intransférables. Ainsi, il est de la
					responsabilité du client de prévenir tout accès par des tiers au site
					internet en ne divulguant pas ces informations. Il est toujours possible
					de se désabonner à tout moment, en envoyant un mail à la société CARREMENT
					BON grâce au formulaire de contact prévu à cet effet.
				</p>
			</li>

			<li>
				<h3>Propriété intellectuelle</h3>
				<p>
					Le contenu du Site est la propriété exclusive de CARREMENT BON et est
					protégé par les lois françaises relatives à la propriété intellectuelle.
				</p>
				<p>
					Toute reproduction totale ou partielle de ce contenu ainsi que des marques
					et logos déposés est strictement interdite et est susceptible de
					constituer un délit de contrefaçon.
				</p>
			</li>

			<li>
				<h3>Droit applicable</h3>
				<p>
					Les présentes Conditions Générales de Vente ci-dessus sont régies par le
					droit français.
				</p>
			</li>

		</ol>
	</div>
</div>
