@if(this.product) {
	<div class="cb-product-item">
		<a class="cb-product-item-link" [routerLink]="[ this.sitemap.shopProduct.route, product.id ]">
			<div
				class="cb-product-item-picture"
				[style.background-image]="'url({0})' | replace : '{0}' : product.img"
			></div>

			<h3 class="cb-product-item-title">{{ product.name }}</h3>

			@if(product.prices.length) {
				<p class="cb-product-item-price">
					{{ product.prices[0].value | toPrice }}
					{{ product.prices[0].unit === "EUR" ? "€" : "" }}
				</p>
			}
		</a>

		<div class="cb-product-item-button">
			<app-cb-button
				color="primary"
				text="ajouter au panier"
				type="button"
				[disabled]="!product.prices.length"
				(click)="product.prices.length ? this.addToCart() : ''"
			></app-cb-button>
		</div>
	</div>
}
