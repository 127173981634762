@if(this.success) {
	<div class="cb-reset-password">
		<div class="cb-reset-password-icon">
			<mat-icon class="cb-reset-password-success">check</mat-icon>
		</div>
	
		<app-cb-button
			class="cb-reset-password-submit"
			color="primary"
			text="retour"
			[routerLink]="sitemap.sign.route"
		></app-cb-button>
	</div>
}

@else {
	<form class="cb-reset-password" [formGroup]="this.resetPasswordForm" (submit)="this.resetPassword()">
		<div class="cb-reset-password-heading">
			<h3>mot de passe oublié ?</h3>
			<p>veuillez renseigner votre adresse e-mail afin de recevoir votre nouveau mot de passe</p>
		</div>
	
		<mat-form-field class="cb-reset-password-form-field" appearance="outline">
			<mat-label>adresse e-mail</mat-label>
			<input matInput type="email" placeholder="adresse e-mail" formControlName="email">
			<mat-icon matSuffix>mail</mat-icon>
			@if (this.resetPasswordForm.controls['email'].hasError('required')) {
				<mat-error>adresse e-mail requise</mat-error>
			}
			@if (this.resetPasswordForm.controls['email'].hasError('email')) {
				<mat-error>adresse e-mail incorrecte</mat-error>
			}
		</mat-form-field>
	
		<app-cb-button
			class="cb-reset-password-submit"
			color="primary"
			text="récupération"
			type="submit"
			[disabled]="this.submitted"
			[loading]="this.submitted"
		></app-cb-button>
	</form>
}