@if (!this.adminPath && this.isAdmin) {
	<div class="cb-admin-bar">
		<div class="cb-row">
			<button mat-flat-button class="cb-admin-bar-button" matTooltip="Accueil" [routerLink]="[this.sitemap.main.route]">
				<mat-icon>home</mat-icon>
			</button>

			<button mat-flat-button class="cb-admin-bar-button" matTooltip="Interface Administrateur" [routerLink]="[this.sitemap.admin.route]">
				<mat-icon>admin_panel_settings</mat-icon>
			</button>
		</div>

		@if (this.user) {
			<div class="cb-row">
				<mat-icon>person</mat-icon>
				<mat-label>{{ this.user.profile.firstName }} {{ this.user.profile.lastName }}</mat-label>
			</div>
		}
	</div>
}

<router-outlet></router-outlet>