import { Component, Inject } from "@angular/core";
import { FormGroup, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";

import { COUNTRIES } from "../../../country";
import { AddressDto } from "../../../dtos/address.dto";
import { Address } from "../../../models/address";
import { Country } from "../../../models/country";
import { User } from "../../../models/user";

import { AccountService } from "../../../services/account/account.service";
import { ApiAddressService } from "../../../services/api/api-address/api-address.service";

import { CbButtonComponent } from "../../cb-button/cb-button.component";

@Component({
	selector: "app-cb-address-form",
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatProgressSpinnerModule,
		MatFormFieldModule,
		MatInputModule,
		MatRadioModule,
		MatSelectModule,
		MatTooltipModule,
		MatCheckboxModule,
		MatButtonModule,
		CbButtonComponent
	],
	templateUrl: "./cb-address-form.component.html",
	styleUrl: "./cb-address-form.component.scss",
})
export class CbAddressFormComponent {

	private user!: User;
	private token!: string;

	public countries: Country[] = COUNTRIES;

	public submitted: boolean = false;
	public isEditMode: boolean = false;

	public addressForm: FormGroup = this.fb.group({
		lastName: [null, Validators.required],
		firstName: [null, Validators.required],
		street: [null, Validators.required],
		zip: [null, Validators.required],
		city: [null, Validators.required],
		country: [this.countries[0].value, Validators.required],
		phone: [null, [ Validators.required, Validators.pattern(/^([+][0-9]{1,3} |[0-9])[0-9] ([0-9]{2} ){3}[0-9]{2}$|^([0-9]{2}){5}$|^([+][0-9]{1,3}[0-9])([0-9]{2}){4}$/) ]],
		isBilledAddress: [true, Validators.required],
		isDefaultBilledAddress: [false, Validators.required],
		isDefault: [false, Validators.required],
	});

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<CbAddressFormComponent>,
		private _account: AccountService,
		private _address: ApiAddressService,
		@Inject(MAT_DIALOG_DATA) private data: Address
	) {
		this._account.currentUser.subscribe((user: User | null): User => this.user = user!);
		this._account.currentUserToken.subscribe((token: string | null) => this.token = token || '');

		if (data) {
			this.isEditMode = true;

			this.addressForm.setValue({
				lastName: data.lastName,
				firstName: data.firstName,
				street: data.street,
				zip: data.zip,
				city: data.city,
				country: data.country,
				phone: data.phone,
				isBilledAddress: data.isBilledAddress,
				isDefaultBilledAddress: data.isDefaultBilledAddress,
				isDefault: data.isDefault
			});

			if(!data.isBilledAddress)
				this.addressForm.controls['isDefaultBilledAddress'].disable();
		}
	}

	public async addressFormProcess(): Promise<void> {
		if(this.addressForm.invalid)
			return;

		this.submitted = true;
		this.addressForm.disable();

		const addressDto: AddressDto = {
			...this.addressForm.value,
		};

		try {
			if (this.isEditMode) {
				const editAddress: Address | undefined = await this._address.update(
					this.token,
					this.data.id!,
					addressDto
				);

				if (editAddress) {
					if (editAddress.isDefault) {
						this.user.profile.addresses
							.filter((address: Address): boolean => editAddress.id !== address.id)
							.map((address: Address): boolean => address.isDefault = false);
					}

					if (editAddress.isDefaultBilledAddress) {
						this.user.profile.addresses
							.filter((address: Address): boolean => editAddress.id !== address.id)
							.map((address: Address): boolean => address.isDefaultBilledAddress = false);
					}

					this.user.profile.addresses = this.user.profile.addresses.map(
						(address: Address): Address => (editAddress.id === address.id) ? editAddress : address
					);

					this._account.updateAccount(this.user, this.token);
					this.dialogRef.close();
				}
			} else {
				const createAddress: Address | undefined = await this._address.add(this.token, addressDto);

				if (createAddress) {
					if (createAddress.isDefault)
						this.user.profile.addresses.map((address: Address): boolean => address.isDefault = false);

					if (createAddress.isDefaultBilledAddress)
						this.user.profile.addresses.map((address: Address): boolean => address.isDefaultBilledAddress = false);

					this.user.profile.addresses.push(createAddress);
					this._account.updateAccount(this.user, this.token);
					this.dialogRef.close();
				}
			}
		}

		catch (err) {
			console.error(err);
		}

		this.addressForm.enable();
		this.submitted = false;
	}

	public cancelEdit(): void {}

}
