import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { SITEMAP, SiteMap } from '../../_commons/sitemap';

import { AccountService } from '../../_commons/services/account/account.service';
import { ApiCheckoutService } from '../../_commons/services/api/api-checkout/api-checkout.service';

import { CbButtonComponent } from '../../_commons/components/cb-button/cb-button.component';
import { CartService } from '../../_commons/services/cart/cart.service';
import { CheckoutService } from '../../_commons/services/checkout/checkout.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
	selector: 'app-checkout-end',
	standalone: true,
	imports: [
		RouterModule,
		MatIconModule,
		MatProgressSpinnerModule,
		CbButtonComponent
	],
	templateUrl: './checkout-end.component.html',
	styleUrl: './checkout-end.component.scss'
})
export class CheckoutEndComponent {

	private token!: string;
	private checkoutId!: string;

	public isLoading: boolean = true;
	public sitemap: SiteMap = SITEMAP;
	public success: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private _apiCheckout: ApiCheckoutService,
		private _account: AccountService,
		private _cart: CartService,
		private _checkout: CheckoutService
	) {

		this.token = this._account.getUserToken()!;

		this.route.queryParamMap.subscribe(async (paramMap: ParamMap): Promise<void> => {
			this.checkoutId = paramMap.get('checkout_id')!;

			if(this.checkoutId)
				this.success = (await this._apiCheckout.check(this.token, this.checkoutId))?.body!.success! || false;

			this.isLoading = false;

			if(this.success) {
				this._cart.resetCart();
				this._checkout.resetCheckout();
			}
		});

	}

}
