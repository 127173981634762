import { Component, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SITEMAP, SiteMap } from '../_commons/sitemap';

@Component({
	selector: 'app-checkout',
	standalone: true,
	imports: [ RouterModule ],
	templateUrl: './checkout.component.html',
	styleUrl: './checkout.component.scss',
	encapsulation: ViewEncapsulation.None
})
export class CheckoutComponent {

	public sitemap: SiteMap = SITEMAP;

}
