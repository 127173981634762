<form class="cb-sign-up" [formGroup]="this.signUpForm" (submit)="this.signUp()">
	<div class="cb-sign-up-heading">
		<h3>pas encore membre ?</h3>
		<p>inscrivez-vous à CARREMENT BON</p>
	</div>

	<mat-form-field class="cb-sign-up-form-field" appearance="outline">
		<mat-label>adresse e-mail</mat-label>
		<input
			matInput
			name="email"
			type="email"
			placeholder="adresse e-mail"
			formControlName="email"
			[(ngModel)]="this.email"
		/>
		<mat-icon matSuffix>mail</mat-icon>
		@if (this.signUpForm.controls['email'].hasError('required')) {
			<mat-error>adresse e-mail requise</mat-error>
		}
		@if (this.signUpForm.controls['email'].hasError('email')) {
			<mat-error>adresse e-mail incorrecte</mat-error>
		}
	</mat-form-field>

	<app-cb-button
		class="cb-sign-up-submit"
		color="primary"
		text="inscription"
		type="submit"
		[disabled]="this.submitted"
		[loading]="this.submitted"
	></app-cb-button>
</form>