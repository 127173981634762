<div class="cb-account-details-reset-password">
	<div class="cb-account-details-reset-password-ctnr">
		<div class="cb-account-details-reset-password-algn">
			<div class="cb-title-ctnr">
				<h2 class="cb-title">Modifier votre mot de passe</h2>
			</div>

			<form class="cb-form" [formGroup]="infoFormGrp">
				<div class="cb-form-short-field-ctnr">
					<div class="cb-form-short-field-algn">
						<mat-form-field
							class="cb-form-short-field cb-form-short-input-left"
							appearance="outline"
						>
							<mat-label>Nouveau mot de passe</mat-label>
							<input
								class="cb-form-input"
								formControlName="password"
								matInput
								placeholder="Nouveau mot de passe"
							/>
						</mat-form-field>
					</div>

					<div class="cb-form-short-field-algn">
						<mat-form-field
							class="cb-form-short-field cb-form-short-input-right"
							appearance="outline"
						>
							<mat-label>Répéter le nouveau mot de passe</mat-label>
							<input
								class="cb-form-input"
								formControlName="repeatPassword"
								placeholder="Répéter le nouveau mot de passe"
								matInput
							/>
						</mat-form-field>
					</div>
				</div>

				<div class="cb-btn-algn">
					<app-cb-button
						mat-flat-button
						class="cb-btn"
						[routerLink]="[this.sitemap.account.route]"
						color="primary"
						text="Confirmer"
					>
					</app-cb-button>
				</div>
			</form>
		</div>
	</div>
</div>
