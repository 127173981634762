<div class="cb-admin">
	<app-admin-menu></app-admin-menu>

	<aside class="cb-admin-output">
		<app-admin-path></app-admin-path>

		<div class="cb-admin-frame">
			<router-outlet></router-outlet>
		</div>
	</aside>
</div>