import { Component } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

import { Product } from "../../../../_commons/models/product";
import { Price } from "../../../../_commons/models/price";
import { ApiProductService } from "../../../../_commons/services/api/api-product/api-product.service";
import { CartService } from "../../../../_commons/services/cart/cart.service";
import { Preview } from "../../../../_commons/models/preview";

import { ToPricePipe } from "../../../../_commons/pipes/to-price/to-price.pipe";
import { ReplacePipe } from "../../../../_commons/pipes/replace/replace.pipe";

import { CbButtonComponent } from "../../../../_commons/components/cb-button/cb-button.component";
import { CbQuantitySelectorComponent } from "../../../../_commons/components/cb-quantity-selector/cb-quantity-selector.component";
import { SITEMAP } from "../../../../_commons/sitemap";

@Component({
	selector: "app-product",
	standalone: true,
	templateUrl: "./product.component.html",
	styleUrl: "./product.component.scss",
	imports: [
		MatButtonModule,
		MatIconModule,
		MatProgressSpinner,
		ReplacePipe,
		ToPricePipe,
		CbButtonComponent,
		CbQuantitySelectorComponent
	],
})
export class ProductComponent {

	public product!: Product;

	public previews: string[] = []
	public currentPreview: string = this.previews[0] || "";
	public priceSelected: Price = this.product?.prices.filter((p: Price) => p.isVisible)[0];
	public amount: number = 1;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private _cart: CartService,
		private _product: ApiProductService
	) {
		this.route.paramMap.subscribe(async (paramMap: ParamMap): Promise<void> => {
			try {
				this.product = (await this._product.getById(+paramMap.get('product')!))?.body!;

				if(!this.product.isAvailable)
					this.router.navigate([ SITEMAP.shop.route ]);

				this.priceSelected = this.product.prices.filter((p: Price) => p.isVisible)[0];
				this.updatePreview();
			}

			catch (error) {
				this.router.navigate([ SITEMAP.shop.route ]);
			}
		});
	}

	public updatePreview(): void {
		this.previews = [ this.product.img, ...this.product.previews.map((p: Preview) => p.preview) ];
		this.currentPreview = this.previews[0];
	}

	public addToCart(): void {
		const selectedProduct: Product = {
			...this.product,
			prices: [ this.priceSelected ]
		};

		this._cart.addToCart(selectedProduct, this.amount);
	}

}
