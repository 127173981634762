import { Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

import { SITEMAP, SiteMap } from "../../../_commons/sitemap";
import { OrderedProduct } from "../../../_commons/models/ordered-product";
import { Product } from "../../../_commons/models/product";

import { CartService } from "../../../_commons/services/cart/cart.service";

import { ReplacePipe } from "../../../_commons/pipes/replace/replace.pipe";
import { ToPricePipe } from "../../../_commons/pipes/to-price/to-price.pipe";

@Component({
	selector: "app-cart-item",
	standalone: true,
	imports: [
		RouterModule,
		MatIconModule,
		MatButtonModule,
		ReplacePipe,
		ToPricePipe
	],
	templateUrl: "./cart-item.component.html",
	styleUrl: "./cart-item.component.scss",
})
export class CartItemComponent {

	@Input() public orderedProduct!: OrderedProduct;
	@Input() public selectedWeight!: number;

	public sitemap: SiteMap = SITEMAP;

	constructor(private _cartService: CartService) {}

	public getProductWeight = (product: Product): number | null => product.prices[0].weight || null;
	public decreaseQuantity = (orderedProduct: OrderedProduct): void => this._cartService.decrementCartItem(orderedProduct);
	public increaseQuantity = (orderedProduct: OrderedProduct): void => this._cartService.incrementCartItem(orderedProduct);
	public removeProduct = (orderedProduct: OrderedProduct): void => this._cartService.removeFromCart(orderedProduct);

}
