<div class="cb-legal-notice">
	<div class="cb-legal-notice-ctnr">
		<h2>Mentions légales</h2>

		<h3>Société et publication :</h3>
		<p>
			Le site www.carrementbon.fr est édité par la société SARL Christine Vallot, au capital social de 1000€, dont le siège est situé au 3, chemin des étoiles, 31330 LAUNAC, immatriculée au RCS de
			Toulouse, sous le numéro 84898886200018.
		</p>
		<p>Numéro de TVA intracommunautaire : FR10 848988862.</p>
		<p>
			Contact : Christine Vallot CARREMENT BON,
			<strong>
				<a href="mailto:contact@carrementbon.fr">contact&#64;carrementbon.fr</a>
			</strong>
		</p>

		<h3>Crédits photos :</h3>
		<p>Stéphane Sudres</p>
		<p>Christine Vallot</p>

		<h3>Réalisation du site :</h3>
		<p>ASC-IT, Entreprise de service informatique</p>
		<p>4 rue Dunkerque France</p>
		<p>31200 Toulouse</p>

		<h3>Données personnelles :</h3>
		<p>
			Conformément à la loi informatique et libertés n° 78-17 du 6/01/1978, vous avez un droit d'accès à vos informations et à les rectifier en vous adressant à :
			<strong>
				<a href="mailto:contact@carrementbon.fr">contact&#64;carrementbon.fr</a>
			</strong>
			, ou par courrier postal à Carrément Bon, 3 chemin des étoiles, 31330 Launac
		</p>

		<h3>Propriété intellectuelle :</h3>
		<p>Les contenus de ce site (design, marque, logo, texte, images) sont la propriété exclusive de la Sarl Christine Vallot Carrément Bon.</p>
		<p>Aucun de ces éléments ne peut être copié, reproduit, modifié ou distribué, sans l'autorisation préalable, écrite et expresse, de ladite société.</p>

		<h3>Hébergement :</h3>
		<a href="https://www.asc-it.fr/"> ASC-IT, Entreprise de service informatique </a>
		<p>
			ASC-IT - 4 rue Dunkerque France, 31200 Toulouse
			<strong>
				<a href="mailto:contact@asc-it.fr">contact&#64;asc-it.fr</a>
			</strong>
		</p>
	</div>
</div>
