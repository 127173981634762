<mat-dialog-content class="cb-admin-products-modal">

	@if (this.isLoading) {
		<div class="cb-admin-products-modal-loading">
			<mat-progress-spinner
				diameter="48"
				mode="indeterminate"
			></mat-progress-spinner>
		</div>
	}

	<div class="cb-admin-products-heading">
		<h2>@if (this.isEditMode) { Modifier le } @else { Ajouter un } produit</h2>
	</div>

	<form class="cb-admin-products-form" [formGroup]="this.productForm" (submit)="this.submit()">
		<div class="cb-admin-products-form-row">
			<mat-checkbox formControlName="isAvailable">Disponible</mat-checkbox>
		</div>

		<div class="cb-admin-products-form-row">
			<mat-form-field class="cb-admin-products-form-field" appearance="outline">
				<mat-label>nom du produit</mat-label>
				<input
					matInput
					#name
					name="name"
					type="text"
					placeholder="nom du produit"
					formControlName="name"
					required
					(ngModelChange)="this.updateSlug($event)"
				/>
				<mat-error>nom du produit requis</mat-error>
			</mat-form-field>
		</div>

		<div class="cb-admin-products-form-row">
			<mat-form-field class="cb-admin-products-form-field" appearance="outline">
				<mat-label>slug</mat-label>
				<input
					matInput
					#slug
					name="slug"
					type="text"
					placeholder="slug"
					formControlName="slug"
					required
					[(ngModel)]="this.slugString"
				/>
				@if (this.productForm.controls['slug'].hasError('required')) {
					<mat-error>slug requis</mat-error>
				}

				@if (this.productForm.controls['slug'].hasError('pattern')) {
					<mat-error>slug incorrect</mat-error>
				}
			</mat-form-field>
		</div>

		<div class="cb-admin-products-form-row">
			<mat-form-field class="cb-admin-products-form-field" appearance="outline">
				<mat-label>url de l'image du produit</mat-label>
				<input
					matInput
					#img
					name="img"
					type="text"
					placeholder="url de l'image du produit (url)"
					formControlName="img"
					required
				/>
				<mat-error>url requise</mat-error>
			</mat-form-field>
		</div>

		<div class="cb-admin-products-form-row">
			<mat-form-field class="cb-admin-products-form-field" appearance="outline">
				<mat-label>description</mat-label>
				<textarea
					matInput
					#description
					name="description"
					type="text"
					placeholder="description"
					formControlName="description"
					required
				></textarea>
				<mat-error>description requise</mat-error>
			</mat-form-field>
		</div>

		<div class="cb-admin-products-form-row">
			<mat-form-field class="cb-admin-products-form-field" appearance="outline">
				<mat-label>catégorie</mat-label>
				<mat-select formControlName="category">
					@for (category of this.categories; track category) {
						<mat-option [value]="category.id">{{ category.name }}</mat-option>
					}
				</mat-select>
				<mat-error>description requise</mat-error>
			</mat-form-field>
		</div>

		<h3>Prix</h3>
		<div class="cb-admin-products-form-row">
			@for (price of this.priceForms; track $index) {
				<div class="cb-admin-products-form-row-price" [formGroup]="this.priceForms[$index]">
					<mat-checkbox
						class="cb-admin-products-price-checkbox"
						formControlName="isVisible"
						[checked]="price.value.isVisible"
					></mat-checkbox>

					<mat-form-field class="cb-admin-products-form-field" appearance="outline">
						<mat-label>poids (g)</mat-label>
						<input
							matInput
							#weight
							[min]="0"
							name="weight"
							type="number"
							placeholder="poids"
							formControlName="weight"
							[value]="price.value.weight"
							required
						/>

						@if (price.controls['weight'].hasError('required')) {
							<mat-error>poids requis</mat-error>
						}
						@if (price.controls['weight'].hasError('pattern')) {
							<mat-error>poids doit être un nombre</mat-error>
						}
					</mat-form-field>
		
					<mat-form-field class="cb-admin-products-form-field" appearance="outline">
						<mat-label>prix (€)</mat-label>
						<input
							matInput
							#value
							[min]="0"
							name="value"
							type="number"
							placeholder="prix"
							formControlName="value"
							[value]="price.value.value"
							required
						/>
						<mat-icon matSuffix>euro</mat-icon>

						@if (price.controls['value'].hasError('required')) {
							<mat-error>prix requis</mat-error>
						}
						@if (price.controls['value'].hasError('pattern')) {
							<mat-error>prix doit être un nombre</mat-error>
						}
					</mat-form-field>
		
					<mat-form-field class="cb-admin-products-form-field" appearance="outline">
						<mat-label>contenant</mat-label>
						<mat-select
							formControlName="container"
							[value]="price.value.container.id"
						>
							@for (productContainer of this.productContainers; track productContainer) {
								<mat-option
									[value]="productContainer.id"
								>{{ productContainer.container }}</mat-option>
							}
						</mat-select>
						<mat-error>contenant requis</mat-error>
					</mat-form-field>
		
					@if (!price.value.id) {
						<button
							class="cb-admin-products-row-delete"
							mat-icon-button
							type="button"
							[disabled]="this.isLoading"
							(click)="this.deletePrice($index)"
						>
							<mat-icon>delete</mat-icon>
						</button>
					}
				</div>
			}

			<button
				mat-icon-button
				type="button"
				[disabled]="this.isLoading"
				(click)="this.addPrice()"
			>
				<mat-icon>add</mat-icon>
			</button>
		</div>
	</form>

	<mat-dialog-actions class="cb-admin-products-form-buttons" align="end">
		<button
			mat-flat-button
			color="accent"
			type="submit"
			[disabled]="this.isLoading || this.isInvalidForms()"
			(click)="this.submit()"
		>
			@if (this.isEditMode) { Modifier } @else { Ajouter }
		</button>

		<button
			mat-flat-button
			mat-dialog-close
			[disabled]="this.isLoading"
		>
			Annuler
		</button>
	</mat-dialog-actions>

</mat-dialog-content>