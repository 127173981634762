import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import deepEqual from 'deep-equal';

import { SHOP_ADDRESSES } from '../../../_commons/constants';
import { Address } from '../../../_commons/models/address';
import { Order } from '../../../_commons/models/order';
import { OrderStatus } from '../../../_commons/models/order-status';

import { OrderService } from '../../../_commons/services/order/order.service';
import { OrderStatusService } from '../../../_commons/services/order-status/order-status.service';
import { ToAddressPipe } from '../../../_commons/pipes/to-address/to-address.pipe';
import { ToDatePipe } from '../../../_commons/pipes/to-date/to-date.pipe';
import { ToPricePipe } from '../../../_commons/pipes/to-price/to-price.pipe';

import { CbOrderStatusProgressComponent } from '../../../_commons/components/cb-order-status-progress/cb-order-status-progress.component';

@Component({
	selector: 'app-admin-orders-item',
	standalone: true,
	imports: [
		MatCardModule,
		MatCheckboxModule,
		MatExpansionModule,
		MatIconModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatTooltipModule,
		ToAddressPipe,
		ToDatePipe,
		ToPricePipe,
		CbOrderStatusProgressComponent
	],
	templateUrl: './admin-orders-item.component.html',
	styleUrl: './admin-orders-item.component.scss'
})
export class AdminOrdersItemComponent {

	@Input() public order!: Order;

	public orderStatuses: OrderStatus[] = [];

	public isLoading: boolean = false;

	constructor(
		private _order: OrderService,
		private _orderStatus: OrderStatusService,
		public dialog: MatDialog
	) {
		this._orderStatus.currentOrderStatuses.subscribe((orderStatus: OrderStatus[]) => this.orderStatuses = orderStatus);
	}

	public isShopAddress = (address: string): boolean => deepEqual(<Address>JSON.parse(address), SHOP_ADDRESSES[0]);

	public async updateOrderStatus(orderStatusId: number): Promise<void> {
		this.order.status = this.orderStatuses.find((o: OrderStatus) => o.id === orderStatusId)!;
		this.isLoading = true;

		const updatedOrder: Order | null = await this._order.updateOrder(this.order);

		if(updatedOrder)
			this.order = updatedOrder;

		this.isLoading = false;
	}

}
